import { useIntl } from 'react-intl';
import { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';

interface CustomVideoProps<T = any> {
  src?: string | undefined;
  controls?: boolean;
  className?: string;
}

const CustomVideo = <T extends any>(props: CustomVideoProps<T>) => {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    setLoading(true);
    const handleLoad = () => setLoading(false);

    const videoCur = videoRef.current;
    if (videoCur) {
      videoCur.addEventListener('loadeddata', handleLoad);
      videoCur.addEventListener('canplay', handleLoad);
      videoCur.load();
    }

    return () => {
      if (videoCur) {
        videoCur.removeEventListener('loadeddata', handleLoad);
        videoCur.removeEventListener('canplay', handleLoad);
      }
    };
  }, [props.src]);

  return (
    <Spin spinning={loading}>
      <video
        ref={videoRef}
        className={`${props.className} rounded width-220 min-width-full`}
        controls={props.controls ?? true}
      >
        <source {...props} />
      </video>
    </Spin>
  );
};

export default CustomVideo;
