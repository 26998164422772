import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AdminRoutes } from './routes/admin/adminRoutes';
import { TrainerRoutes } from './routes/trainer/trainerRoutes';
import Terms from './routes/terms';
import Privacy from './routes/privacy';

const RoutesApp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/*" element={<TrainerRoutes />} />
        <Route path="admin/*" element={<AdminRoutes />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesApp;
