/* tslint:disable */
/* eslint-disable */
/**
 * GYM_TRAINER_DEV
 * Api for development - v1.0
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Administrator
 */
export interface Administrator {
    /**
     * 
     * @type {Asset}
     * @memberof Administrator
     */
    'avatar'?: Asset;
    /**
     * 
     * @type {User}
     * @memberof Administrator
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'firstNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'lastNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'dob': string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'postCode': string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'avatarId': string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface AdministratorControllerGet200Response
 */
export interface AdministratorControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof AdministratorControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AdministratorControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof AdministratorControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Administrator>}
     * @memberof AdministratorControllerGet200Response
     */
    'content'?: Array<Administrator>;
}
/**
 * 
 * @export
 * @interface AdministratorControllerGet200ResponseAllOf
 */
export interface AdministratorControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<Administrator>}
     * @memberof AdministratorControllerGet200ResponseAllOf
     */
    'content'?: Array<Administrator>;
}
/**
 * 
 * @export
 * @interface ArrayAnswer
 */
export interface ArrayAnswer {
    /**
     * 
     * @type {string}
     * @memberof ArrayAnswer
     */
    'lessonId': string;
    /**
     * 
     * @type {string}
     * @memberof ArrayAnswer
     */
    'questionAddOnTimeId': string;
    /**
     * 
     * @type {Array<CreateLessonQuestionUserAnswerDto>}
     * @memberof ArrayAnswer
     */
    'arrayAnswer'?: Array<CreateLessonQuestionUserAnswerDto>;
}
/**
 * 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'type': AssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'mimeType': string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'fileSize': number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'preview': string;
    /**
     * 
     * @type {AssetFocalPoint}
     * @memberof Asset
     */
    'focalPoint': AssetFocalPoint;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'parentId': number;
    /**
     * 
     * @type {Array<Asset>}
     * @memberof Asset
     */
    'children': Array<Asset>;
    /**
     * 
     * @type {Asset}
     * @memberof Asset
     */
    'parent': Asset;
    /**
     * 
     * @type {Array<Help>}
     * @memberof Asset
     */
    'help'?: Array<Help>;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'deletedAt': string;
}

export const AssetTypeEnum = {
    Image: 'IMAGE',
    Video: 'VIDEO',
    Binary: 'BINARY',
    Folder: 'FOLDER'
} as const;

export type AssetTypeEnum = typeof AssetTypeEnum[keyof typeof AssetTypeEnum];

/**
 * 
 * @export
 * @interface AssetFocalPoint
 */
export interface AssetFocalPoint {
    /**
     * 
     * @type {number}
     * @memberof AssetFocalPoint
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetFocalPoint
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface AuthenticationMethod
 */
export interface AuthenticationMethod {
    /**
     * 
     * @type {User}
     * @memberof AuthenticationMethod
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationMethod
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationMethod
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationMethod
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationMethod
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationMethod
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationMethod
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface CheckUsername
 */
export interface CheckUsername {
    /**
     * 
     * @type {string}
     * @memberof CheckUsername
     */
    'identifier': string;
    /**
     * 
     * @type {string}
     * @memberof CheckUsername
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface Course
 */
export interface Course {
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'introduce': string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'backgroundId': string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'userId': string;
    /**
     * 
     * @type {Asset}
     * @memberof Course
     */
    'background'?: Asset;
    /**
     * 
     * @type {Array<Lesson>}
     * @memberof Course
     */
    'lesson': Array<Lesson>;
    /**
     * 
     * @type {Array<LessonLearn>}
     * @memberof Course
     */
    'lessonLearn': Array<LessonLearn>;
    /**
     * 
     * @type {User}
     * @memberof Course
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface CourseControllerGet200Response
 */
export interface CourseControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof CourseControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof CourseControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof CourseControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Course>}
     * @memberof CourseControllerGet200Response
     */
    'content'?: Array<Course>;
}
/**
 * 
 * @export
 * @interface CourseControllerGet200ResponseAllOf
 */
export interface CourseControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<Course>}
     * @memberof CourseControllerGet200ResponseAllOf
     */
    'content'?: Array<Course>;
}
/**
 * 
 * @export
 * @interface CreateAdminDto
 */
export interface CreateAdminDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'firstNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'lastNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'postCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'gender'?: CreateAdminDtoGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'avatarId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAdminDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAdminDto
     */
    'roleIds'?: Array<string>;
}

export const CreateAdminDtoGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER'
} as const;

export type CreateAdminDtoGenderEnum = typeof CreateAdminDtoGenderEnum[keyof typeof CreateAdminDtoGenderEnum];

/**
 * 
 * @export
 * @interface CreateCourseDto
 */
export interface CreateCourseDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCourseDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCourseDto
     */
    'introduce'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCourseDto
     */
    'backgroundId'?: string;
    /**
     * 
     * @type {Array<CreateLessonDto>}
     * @memberof CreateCourseDto
     */
    'lesson'?: Array<CreateLessonDto>;
}
/**
 * 
 * @export
 * @interface CreateCustomerDto
 */
export interface CreateCustomerDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'firstNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'lastNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'postCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'gender'?: CreateCustomerDtoGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'avatarId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCustomerDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'backgroundId'?: string;
}

export const CreateCustomerDtoGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER'
} as const;

export type CreateCustomerDtoGenderEnum = typeof CreateCustomerDtoGenderEnum[keyof typeof CreateCustomerDtoGenderEnum];

/**
 * 
 * @export
 * @interface CreateDocsDto
 */
export interface CreateDocsDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDocsDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocsDto
     */
    'content': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateDocsDto
     */
    'fileIds': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateFaqDto
 */
export interface CreateFaqDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFaqDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFaqDto
     */
    'content': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateFaqDto
     */
    'fileIds': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateFeedbackDto
 */
export interface CreateFeedbackDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFeedbackDto
     */
    'level': CreateFeedbackDtoLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedbackDto
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedbackDto
     */
    'userId': string;
}

export const CreateFeedbackDtoLevelEnum = {
    Bad: 'BAD',
    Normal: 'NORMAL',
    Rather: 'RATHER',
    Good: 'GOOD',
    Excellent: 'EXCELLENT'
} as const;

export type CreateFeedbackDtoLevelEnum = typeof CreateFeedbackDtoLevelEnum[keyof typeof CreateFeedbackDtoLevelEnum];

/**
 * 
 * @export
 * @interface CreateFolderDto
 */
export interface CreateFolderDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFolderDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFolderDto
     */
    'assetFolderId'?: string;
}
/**
 * 
 * @export
 * @interface CreateLessonDto
 */
export interface CreateLessonDto {
    /**
     * 
     * @type {number}
     * @memberof CreateLessonDto
     */
    'index'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateLessonDto
     */
    'limitedTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateLessonDto
     */
    'progress'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateLessonDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLessonDto
     */
    'introduce'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLessonDto
     */
    'videoId'?: string;
}
/**
 * 
 * @export
 * @interface CreateLessonLearn
 */
export interface CreateLessonLearn {
    /**
     * 
     * @type {number}
     * @memberof CreateLessonLearn
     */
    'progress': number;
    /**
     * 
     * @type {string}
     * @memberof CreateLessonLearn
     */
    'lessonId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLessonLearn
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLessonLearn
     */
    'customerId': string;
}
/**
 * 
 * @export
 * @interface CreateLessonLearnCheck
 */
export interface CreateLessonLearnCheck {
    /**
     * 
     * @type {string}
     * @memberof CreateLessonLearnCheck
     */
    'lessonLearnId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLessonLearnCheck
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLessonLearnCheck
     */
    'avatarId': string;
}
/**
 * 
 * @export
 * @interface CreateLessonQuestionAnswerDto
 */
export interface CreateLessonQuestionAnswerDto {
    /**
     * 
     * @type {string}
     * @memberof CreateLessonQuestionAnswerDto
     */
    'answer'?: string;
}
/**
 * 
 * @export
 * @interface CreateLessonQuestionDto
 */
export interface CreateLessonQuestionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateLessonQuestionDto
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLessonQuestionDto
     */
    'answer': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLessonQuestionDto
     */
    'lessonId': string;
    /**
     * 
     * @type {Array<CreateLessonQuestionAnswerDto>}
     * @memberof CreateLessonQuestionDto
     */
    'lessonQuestionAnswer'?: Array<CreateLessonQuestionAnswerDto>;
}
/**
 * 
 * @export
 * @interface CreateLessonQuestionUserAnswerDto
 */
export interface CreateLessonQuestionUserAnswerDto {
    /**
     * 
     * @type {string}
     * @memberof CreateLessonQuestionUserAnswerDto
     */
    'lessonQuestionId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLessonQuestionUserAnswerDto
     */
    'answer'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLessonQuestionUserAnswerDto
     */
    'LessonQuestionAnswerId'?: string;
}
/**
 * 
 * @export
 * @interface CreateNewsDto
 */
export interface CreateNewsDto {
    /**
     * 
     * @type {string}
     * @memberof CreateNewsDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewsDto
     */
    'content'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateNewsDto
     */
    'isVisible': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateNewsDto
     */
    'avatarId'?: string;
}
/**
 * 
 * @export
 * @interface CreateRecruitDto
 */
export interface CreateRecruitDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRecruitDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRecruitDto
     */
    'content'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRecruitDto
     */
    'isVisible': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateRecruitDto
     */
    'avatarId'?: string;
}
/**
 * 
 * @export
 * @interface CreateRoleDto
 */
export interface CreateRoleDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    'code': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRoleDto
     */
    'permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateSurveyAnswerDto
 */
export interface CreateSurveyAnswerDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSurveyAnswerDto
     */
    'answer': string;
}
/**
 * 
 * @export
 * @interface CreateSurveyQuestionDto
 */
export interface CreateSurveyQuestionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSurveyQuestionDto
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSurveyQuestionDto
     */
    'type': CreateSurveyQuestionDtoTypeEnum;
    /**
     * 
     * @type {Array<CreateSurveyAnswerDto>}
     * @memberof CreateSurveyQuestionDto
     */
    'answers'?: Array<CreateSurveyAnswerDto>;
}

export const CreateSurveyQuestionDtoTypeEnum = {
    Option: 'OPTION',
    Text: 'TEXT'
} as const;

export type CreateSurveyQuestionDtoTypeEnum = typeof CreateSurveyQuestionDtoTypeEnum[keyof typeof CreateSurveyQuestionDtoTypeEnum];

/**
 * 
 * @export
 * @interface CreateTrainerDto
 */
export interface CreateTrainerDto {
    /**
     * 
     * @type {string}
     * @memberof CreateTrainerDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrainerDto
     */
    'firstNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrainerDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrainerDto
     */
    'lastNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrainerDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrainerDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrainerDto
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrainerDto
     */
    'postCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrainerDto
     */
    'gender'?: CreateTrainerDtoGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateTrainerDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrainerDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrainerDto
     */
    'avatarId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTrainerDto
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateTrainerDto
     */
    'introduce'?: string;
}

export const CreateTrainerDtoGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER'
} as const;

export type CreateTrainerDtoGenderEnum = typeof CreateTrainerDtoGenderEnum[keyof typeof CreateTrainerDtoGenderEnum];

/**
 * 
 * @export
 * @interface CreateWorkoutScheduleDto
 */
export interface CreateWorkoutScheduleDto {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkoutScheduleDto
     */
    'day': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateWorkoutScheduleDto
     */
    'status'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkoutScheduleDto
     */
    'amFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkoutScheduleDto
     */
    'amTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkoutScheduleDto
     */
    'pmFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkoutScheduleDto
     */
    'pmTo'?: string;
}
/**
 * 
 * @export
 * @interface CreatediscussionsDto
 */
export interface CreatediscussionsDto {
    /**
     * 
     * @type {string}
     * @memberof CreatediscussionsDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatediscussionsDto
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatediscussionsDto
     */
    'timeStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatediscussionsDto
     */
    'timeEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatediscussionsDto
     */
    'roomCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatediscussionsDto
     */
    'roomPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatediscussionsDto
     */
    'roomLink'?: string;
    /**
     * 
     * @type {Array<IdCustomer>}
     * @memberof CreatediscussionsDto
     */
    'customers'?: Array<IdCustomer>;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'backgroundId': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'status': CustomerStatusEnum;
    /**
     * 
     * @type {Asset}
     * @memberof Customer
     */
    'background'?: Asset;
    /**
     * 
     * @type {Asset}
     * @memberof Customer
     */
    'avatar'?: Asset;
    /**
     * 
     * @type {User}
     * @memberof Customer
     */
    'user': User;
    /**
     * 
     * @type {Array<LessonLearn>}
     * @memberof Customer
     */
    'lessonLearn': Array<LessonLearn>;
    /**
     * 
     * @type {Array<CustomerCourse>}
     * @memberof Customer
     */
    'customerCourse': Array<CustomerCourse>;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'firstNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'lastNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'dob': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'postCode': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'avatarId': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'deletedAt': string;
}

export const CustomerStatusEnum = {
    Normal: 'NORMAL',
    Pending: 'PENDING',
    Accept: 'ACCEPT',
    Cancel: 'CANCEL'
} as const;

export type CustomerStatusEnum = typeof CustomerStatusEnum[keyof typeof CustomerStatusEnum];

/**
 * 
 * @export
 * @interface CustomerControllerGet200Response
 */
export interface CustomerControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Customer>}
     * @memberof CustomerControllerGet200Response
     */
    'content'?: Array<Customer>;
}
/**
 * 
 * @export
 * @interface CustomerControllerGet200ResponseAllOf
 */
export interface CustomerControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<Customer>}
     * @memberof CustomerControllerGet200ResponseAllOf
     */
    'content'?: Array<Customer>;
}
/**
 * 
 * @export
 * @interface CustomerCourse
 */
export interface CustomerCourse {
    /**
     * 
     * @type {string}
     * @memberof CustomerCourse
     */
    'status': CustomerCourseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerCourse
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCourse
     */
    'courseId': string;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerCourse
     */
    'customer'?: Customer;
    /**
     * 
     * @type {Course}
     * @memberof CustomerCourse
     */
    'course'?: Course;
    /**
     * 
     * @type {string}
     * @memberof CustomerCourse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCourse
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCourse
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCourse
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCourse
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCourse
     */
    'deletedAt': string;
}

export const CustomerCourseStatusEnum = {
    Normal: 'NORMAL',
    Pending: 'PENDING',
    Accept: 'ACCEPT',
    Cancel: 'CANCEL'
} as const;

export type CustomerCourseStatusEnum = typeof CustomerCourseStatusEnum[keyof typeof CustomerCourseStatusEnum];

/**
 * 
 * @export
 * @interface CustomerCourseControllerGet200Response
 */
export interface CustomerCourseControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof CustomerCourseControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerCourseControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerCourseControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<CustomerCourse>}
     * @memberof CustomerCourseControllerGet200Response
     */
    'content'?: Array<CustomerCourse>;
}
/**
 * 
 * @export
 * @interface CustomerCourseControllerGet200ResponseAllOf
 */
export interface CustomerCourseControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<CustomerCourse>}
     * @memberof CustomerCourseControllerGet200ResponseAllOf
     */
    'content'?: Array<CustomerCourse>;
}
/**
 * 
 * @export
 * @interface Discussions
 */
export interface Discussions {
    /**
     * 
     * @type {string}
     * @memberof Discussions
     */
    'timeStart': string;
    /**
     * 
     * @type {string}
     * @memberof Discussions
     */
    'timeEnd': string;
    /**
     * 
     * @type {object}
     * @memberof Discussions
     */
    'roomCode': object;
    /**
     * 
     * @type {object}
     * @memberof Discussions
     */
    'roomPassword': object;
    /**
     * 
     * @type {object}
     * @memberof Discussions
     */
    'roomLink': object;
    /**
     * 
     * @type {string}
     * @memberof Discussions
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Discussions
     */
    'note': string;
    /**
     * 
     * @type {string}
     * @memberof Discussions
     */
    'userId'?: string;
    /**
     * 
     * @type {User}
     * @memberof Discussions
     */
    'user'?: User;
    /**
     * 
     * @type {Array<Customer>}
     * @memberof Discussions
     */
    'customers'?: Array<Customer>;
    /**
     * 
     * @type {string}
     * @memberof Discussions
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Discussions
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Discussions
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Discussions
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Discussions
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Discussions
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface DocsControllerGet200Response
 */
export interface DocsControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof DocsControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof DocsControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof DocsControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Help>}
     * @memberof DocsControllerGet200Response
     */
    'content'?: Array<Help>;
}
/**
 * 
 * @export
 * @interface DocsControllerGet200ResponseAllOf
 */
export interface DocsControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<Help>}
     * @memberof DocsControllerGet200ResponseAllOf
     */
    'content'?: Array<Help>;
}
/**
 * 
 * @export
 * @interface Feedback
 */
export interface Feedback {
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'level': FeedbackLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'comment': string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'userId': string;
    /**
     * 
     * @type {User}
     * @memberof Feedback
     */
    'author'?: User;
    /**
     * 
     * @type {User}
     * @memberof Feedback
     */
    'trainer'?: User;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'deletedAt': string;
}

export const FeedbackLevelEnum = {
    Bad: 'BAD',
    Normal: 'NORMAL',
    Rather: 'RATHER',
    Good: 'GOOD',
    Excellent: 'EXCELLENT'
} as const;

export type FeedbackLevelEnum = typeof FeedbackLevelEnum[keyof typeof FeedbackLevelEnum];

/**
 * 
 * @export
 * @interface FilterOtpDto
 */
export interface FilterOtpDto {
    /**
     * 
     * @type {string}
     * @memberof FilterOtpDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof FilterOtpDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof FilterOtpDto
     */
    'otpType'?: string;
}
/**
 * 
 * @export
 * @interface ForgotPasswordDto
 */
export interface ForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordDto
     */
    'otpCode': string;
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordDto
     */
    'passwordConfirm': string;
}
/**
 * 
 * @export
 * @interface Help
 */
export interface Help {
    /**
     * 
     * @type {string}
     * @memberof Help
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Help
     */
    'type': HelpTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Help
     */
    'content': string;
    /**
     * 
     * @type {Array<Asset>}
     * @memberof Help
     */
    'assets': Array<Asset>;
    /**
     * 
     * @type {string}
     * @memberof Help
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Help
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Help
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Help
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Help
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Help
     */
    'deletedAt': string;
}

export const HelpTypeEnum = {
    Docs: 'DOCS',
    Faq: 'FAQ'
} as const;

export type HelpTypeEnum = typeof HelpTypeEnum[keyof typeof HelpTypeEnum];

/**
 * 
 * @export
 * @interface IdCustomer
 */
export interface IdCustomer {
    /**
     * 
     * @type {string}
     * @memberof IdCustomer
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface Lesson
 */
export interface Lesson {
    /**
     * 
     * @type {number}
     * @memberof Lesson
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof Lesson
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Lesson
     */
    'introduce': string;
    /**
     * 
     * @type {string}
     * @memberof Lesson
     */
    'limitedTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Lesson
     */
    'videoId': string;
    /**
     * 
     * @type {string}
     * @memberof Lesson
     */
    'courseId': string;
    /**
     * 
     * @type {Asset}
     * @memberof Lesson
     */
    'video'?: Asset;
    /**
     * 
     * @type {Course}
     * @memberof Lesson
     */
    'course'?: Course;
    /**
     * 
     * @type {Array<LessonQuestion>}
     * @memberof Lesson
     */
    'lessonQuestion': Array<LessonQuestion>;
    /**
     * 
     * @type {Array<LessonQuestionAddOnTime>}
     * @memberof Lesson
     */
    'lessonQuestionAddOnTime': Array<LessonQuestionAddOnTime>;
    /**
     * 
     * @type {Array<LessonLearn>}
     * @memberof Lesson
     */
    'lessonLearn'?: Array<LessonLearn>;
    /**
     * 
     * @type {string}
     * @memberof Lesson
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Lesson
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Lesson
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Lesson
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Lesson
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Lesson
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface LessonControllerGet200Response
 */
export interface LessonControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof LessonControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof LessonControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof LessonControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Lesson>}
     * @memberof LessonControllerGet200Response
     */
    'content'?: Array<Lesson>;
}
/**
 * 
 * @export
 * @interface LessonControllerGet200ResponseAllOf
 */
export interface LessonControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<Lesson>}
     * @memberof LessonControllerGet200ResponseAllOf
     */
    'content'?: Array<Lesson>;
}
/**
 * 
 * @export
 * @interface LessonLearn
 */
export interface LessonLearn {
    /**
     * 
     * @type {number}
     * @memberof LessonLearn
     */
    'progress': number;
    /**
     * 
     * @type {string}
     * @memberof LessonLearn
     */
    'lessonId': string;
    /**
     * 
     * @type {string}
     * @memberof LessonLearn
     */
    'courseId': string;
    /**
     * 
     * @type {string}
     * @memberof LessonLearn
     */
    'customerId': string;
    /**
     * 
     * @type {Lesson}
     * @memberof LessonLearn
     */
    'lesson'?: Lesson;
    /**
     * 
     * @type {Course}
     * @memberof LessonLearn
     */
    'course'?: Course;
    /**
     * 
     * @type {Customer}
     * @memberof LessonLearn
     */
    'customer'?: Customer;
    /**
     * 
     * @type {Array<LessonLearnCheck>}
     * @memberof LessonLearn
     */
    'lessonLearnCheck'?: Array<LessonLearnCheck>;
    /**
     * 
     * @type {string}
     * @memberof LessonLearn
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LessonLearn
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof LessonLearn
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof LessonLearn
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof LessonLearn
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof LessonLearn
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface LessonLearnCheck
 */
export interface LessonLearnCheck {
    /**
     * 
     * @type {string}
     * @memberof LessonLearnCheck
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof LessonLearnCheck
     */
    'lessonLearnId': string;
    /**
     * 
     * @type {string}
     * @memberof LessonLearnCheck
     */
    'avatarId': string;
    /**
     * 
     * @type {LessonLearn}
     * @memberof LessonLearnCheck
     */
    'lessonLearn'?: LessonLearn;
    /**
     * 
     * @type {Asset}
     * @memberof LessonLearnCheck
     */
    'avatar': Asset;
    /**
     * 
     * @type {string}
     * @memberof LessonLearnCheck
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LessonLearnCheck
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof LessonLearnCheck
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof LessonLearnCheck
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof LessonLearnCheck
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof LessonLearnCheck
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface LessonLearnControllerGetByUserId200Response
 */
export interface LessonLearnControllerGetByUserId200Response {
    /**
     * 
     * @type {number}
     * @memberof LessonLearnControllerGetByUserId200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof LessonLearnControllerGetByUserId200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof LessonLearnControllerGetByUserId200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<LessonLearn>}
     * @memberof LessonLearnControllerGetByUserId200Response
     */
    'content'?: Array<LessonLearn>;
}
/**
 * 
 * @export
 * @interface LessonLearnControllerGetByUserId200ResponseAllOf
 */
export interface LessonLearnControllerGetByUserId200ResponseAllOf {
    /**
     * 
     * @type {Array<LessonLearn>}
     * @memberof LessonLearnControllerGetByUserId200ResponseAllOf
     */
    'content'?: Array<LessonLearn>;
}
/**
 * 
 * @export
 * @interface LessonQuestion
 */
export interface LessonQuestion {
    /**
     * 
     * @type {string}
     * @memberof LessonQuestion
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestion
     */
    'answer': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestion
     */
    'lessonId': string;
    /**
     * 
     * @type {Lesson}
     * @memberof LessonQuestion
     */
    'lesson': Lesson;
    /**
     * 
     * @type {Array<LessonQuestionAnswer>}
     * @memberof LessonQuestion
     */
    'lessonQuestionAnswer': Array<LessonQuestionAnswer>;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestion
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestion
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestion
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestion
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestion
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestion
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface LessonQuestionAddOnTime
 */
export interface LessonQuestionAddOnTime {
    /**
     * 
     * @type {number}
     * @memberof LessonQuestionAddOnTime
     */
    'totolQuestion': number;
    /**
     * 
     * @type {number}
     * @memberof LessonQuestionAddOnTime
     */
    'correctly': number;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAddOnTime
     */
    'timeStart': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAddOnTime
     */
    'timeEnd': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAddOnTime
     */
    'timeCompleted': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAddOnTime
     */
    'lessonId': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAddOnTime
     */
    'userId': string;
    /**
     * 
     * @type {Lesson}
     * @memberof LessonQuestionAddOnTime
     */
    'Lesson': Lesson;
    /**
     * 
     * @type {User}
     * @memberof LessonQuestionAddOnTime
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAddOnTime
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAddOnTime
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAddOnTime
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAddOnTime
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAddOnTime
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAddOnTime
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface LessonQuestionAnswer
 */
export interface LessonQuestionAnswer {
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAnswer
     */
    'answer': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAnswer
     */
    'lessonQuestionId': string;
    /**
     * 
     * @type {LessonQuestion}
     * @memberof LessonQuestionAnswer
     */
    'lessonQuestion': LessonQuestion;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAnswer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAnswer
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAnswer
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAnswer
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAnswer
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof LessonQuestionAnswer
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface LessonquestionAddOnTimeControllerGetByUserId200Response
 */
export interface LessonquestionAddOnTimeControllerGetByUserId200Response {
    /**
     * 
     * @type {number}
     * @memberof LessonquestionAddOnTimeControllerGetByUserId200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof LessonquestionAddOnTimeControllerGetByUserId200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof LessonquestionAddOnTimeControllerGetByUserId200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<LessonQuestionAddOnTime>}
     * @memberof LessonquestionAddOnTimeControllerGetByUserId200Response
     */
    'content'?: Array<LessonQuestionAddOnTime>;
}
/**
 * 
 * @export
 * @interface LessonquestionAddOnTimeControllerGetByUserId200ResponseAllOf
 */
export interface LessonquestionAddOnTimeControllerGetByUserId200ResponseAllOf {
    /**
     * 
     * @type {Array<LessonQuestionAddOnTime>}
     * @memberof LessonquestionAddOnTimeControllerGetByUserId200ResponseAllOf
     */
    'content'?: Array<LessonQuestionAddOnTime>;
}
/**
 * 
 * @export
 * @interface LessonquestionControllerGetByCourseId200Response
 */
export interface LessonquestionControllerGetByCourseId200Response {
    /**
     * 
     * @type {number}
     * @memberof LessonquestionControllerGetByCourseId200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof LessonquestionControllerGetByCourseId200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof LessonquestionControllerGetByCourseId200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<LessonQuestion>}
     * @memberof LessonquestionControllerGetByCourseId200Response
     */
    'content'?: Array<LessonQuestion>;
}
/**
 * 
 * @export
 * @interface LessonquestionControllerGetByCourseId200ResponseAllOf
 */
export interface LessonquestionControllerGetByCourseId200ResponseAllOf {
    /**
     * 
     * @type {Array<LessonQuestion>}
     * @memberof LessonquestionControllerGetByCourseId200ResponseAllOf
     */
    'content'?: Array<LessonQuestion>;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginResponseDto
 */
export interface LoginResponseDto {
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface News
 */
export interface News {
    /**
     * 
     * @type {string}
     * @memberof News
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    'type': NewsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    'content': string;
    /**
     * 
     * @type {boolean}
     * @memberof News
     */
    'isVisible': boolean;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    'avatarId': string;
    /**
     * 
     * @type {Asset}
     * @memberof News
     */
    'avatar'?: Asset;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    'deletedAt': string;
}

export const NewsTypeEnum = {
    News: 'NEWS',
    Recruit: 'RECRUIT'
} as const;

export type NewsTypeEnum = typeof NewsTypeEnum[keyof typeof NewsTypeEnum];

/**
 * 
 * @export
 * @interface NewsControllerGet200Response
 */
export interface NewsControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof NewsControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof NewsControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof NewsControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<News>}
     * @memberof NewsControllerGet200Response
     */
    'content'?: Array<News>;
}
/**
 * 
 * @export
 * @interface NewsControllerGet200ResponseAllOf
 */
export interface NewsControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<News>}
     * @memberof NewsControllerGet200ResponseAllOf
     */
    'content'?: Array<News>;
}
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    'isRead': boolean;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'newId': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'discussionId': string;
    /**
     * 
     * @type {News}
     * @memberof Notification
     */
    'news': News;
    /**
     * 
     * @type {Discussions}
     * @memberof Notification
     */
    'discussions': Discussions;
    /**
     * 
     * @type {Customer}
     * @memberof Notification
     */
    'customer': Customer;
    /**
     * 
     * @type {NotificationRead}
     * @memberof Notification
     */
    'notificationRead'?: NotificationRead;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface NotificationControllerGet200Response
 */
export interface NotificationControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof NotificationControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof NotificationControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof NotificationControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Notification>}
     * @memberof NotificationControllerGet200Response
     */
    'content'?: Array<Notification>;
}
/**
 * 
 * @export
 * @interface NotificationControllerGet200ResponseAllOf
 */
export interface NotificationControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<Notification>}
     * @memberof NotificationControllerGet200ResponseAllOf
     */
    'content'?: Array<Notification>;
}
/**
 * 
 * @export
 * @interface NotificationRead
 */
export interface NotificationRead {
    /**
     * 
     * @type {string}
     * @memberof NotificationRead
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRead
     */
    'notificationId': string;
    /**
     * 
     * @type {Customer}
     * @memberof NotificationRead
     */
    'customer': Customer;
    /**
     * 
     * @type {Notification}
     * @memberof NotificationRead
     */
    'notification': Notification;
    /**
     * 
     * @type {string}
     * @memberof NotificationRead
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRead
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRead
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRead
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRead
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRead
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface Otp
 */
export interface Otp {
    /**
     * 
     * @type {string}
     * @memberof Otp
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof Otp
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Otp
     */
    'otpCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof Otp
     */
    'isUsed': boolean;
    /**
     * 
     * @type {string}
     * @memberof Otp
     */
    'expiredTime': string;
    /**
     * 
     * @type {string}
     * @memberof Otp
     */
    'userId': string;
    /**
     * 
     * @type {User}
     * @memberof Otp
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof Otp
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Otp
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Otp
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Otp
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Otp
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Otp
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface PaginationResponse
 */
export interface PaginationResponse {
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PermissionDefinitionConfig
 */
export interface PermissionDefinitionConfig {
    /**
     * 
     * @type {string}
     * @memberof PermissionDefinitionConfig
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionDefinitionConfig
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionDefinitionConfig
     */
    'assignable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionDefinitionConfig
     */
    'internal'?: boolean;
}
/**
 * 
 * @export
 * @interface PermissionGroupDto
 */
export interface PermissionGroupDto {
    /**
     * 
     * @type {string}
     * @memberof PermissionGroupDto
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionGroupDto
     */
    'description': string;
    /**
     * 
     * @type {Array<PermissionDefinitionConfig>}
     * @memberof PermissionGroupDto
     */
    'permissions': Array<PermissionDefinitionConfig>;
}
/**
 * 
 * @export
 * @interface QuestionDto
 */
export interface QuestionDto {
    /**
     * 
     * @type {string}
     * @memberof QuestionDto
     */
    'limitedTime': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionDto
     */
    'lessonId': string;
    /**
     * 
     * @type {Array<CreateLessonQuestionDto>}
     * @memberof QuestionDto
     */
    'lessonQuestion': Array<CreateLessonQuestionDto>;
}
/**
 * 
 * @export
 * @interface RegisterCustomerDto
 */
export interface RegisterCustomerDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'firstNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'lastNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'otpCode': string;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'code'?: string;
    /**
     * 
     * @type {Array<User>}
     * @memberof Role
     */
    'users'?: Array<User>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Role
     */
    'permissions': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface RoleControllerGet200Response
 */
export interface RoleControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof RoleControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof RoleControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof RoleControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Role>}
     * @memberof RoleControllerGet200Response
     */
    'content'?: Array<Role>;
}
/**
 * 
 * @export
 * @interface RoleControllerGet200ResponseAllOf
 */
export interface RoleControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<Role>}
     * @memberof RoleControllerGet200ResponseAllOf
     */
    'content'?: Array<Role>;
}
/**
 * 
 * @export
 * @interface SuccessResponseDto
 */
export interface SuccessResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof SuccessResponseDto
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface SurveyAnswer
 */
export interface SurveyAnswer {
    /**
     * 
     * @type {string}
     * @memberof SurveyAnswer
     */
    'answer': string;
    /**
     * 
     * @type {string}
     * @memberof SurveyAnswer
     */
    'questionId': string;
    /**
     * 
     * @type {SurveyQuestion}
     * @memberof SurveyAnswer
     */
    'question': SurveyQuestion;
    /**
     * 
     * @type {string}
     * @memberof SurveyAnswer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SurveyAnswer
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof SurveyAnswer
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof SurveyAnswer
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof SurveyAnswer
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof SurveyAnswer
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface SurveyQuestion
 */
export interface SurveyQuestion {
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestion
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestion
     */
    'type': SurveyQuestionTypeEnum;
    /**
     * 
     * @type {Array<SurveyAnswer>}
     * @memberof SurveyQuestion
     */
    'answers': Array<SurveyAnswer>;
    /**
     * 
     * @type {Array<UserSurveyAnswer>}
     * @memberof SurveyQuestion
     */
    'userSurveyAnswer': Array<UserSurveyAnswer>;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestion
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestion
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestion
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestion
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestion
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestion
     */
    'deletedAt': string;
}

export const SurveyQuestionTypeEnum = {
    Option: 'OPTION',
    Text: 'TEXT'
} as const;

export type SurveyQuestionTypeEnum = typeof SurveyQuestionTypeEnum[keyof typeof SurveyQuestionTypeEnum];

/**
 * 
 * @export
 * @interface SurveyQuestionControllerGet200Response
 */
export interface SurveyQuestionControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof SurveyQuestionControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof SurveyQuestionControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof SurveyQuestionControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<SurveyQuestion>}
     * @memberof SurveyQuestionControllerGet200Response
     */
    'content'?: Array<SurveyQuestion>;
}
/**
 * 
 * @export
 * @interface SurveyQuestionControllerGet200ResponseAllOf
 */
export interface SurveyQuestionControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<SurveyQuestion>}
     * @memberof SurveyQuestionControllerGet200ResponseAllOf
     */
    'content'?: Array<SurveyQuestion>;
}
/**
 * 
 * @export
 * @interface Trainer
 */
export interface Trainer {
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'introduce': string;
    /**
     * 
     * @type {Asset}
     * @memberof Trainer
     */
    'avatar'?: Asset;
    /**
     * 
     * @type {User}
     * @memberof Trainer
     */
    'user': User;
    /**
     * 
     * @type {Array<WorkoutSchedule>}
     * @memberof Trainer
     */
    'workoutSchedule': Array<WorkoutSchedule>;
    /**
     * 
     * @type {Array<Feedback>}
     * @memberof Trainer
     */
    'feedback': Array<Feedback>;
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'firstNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'lastNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'dob': string;
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'postCode': string;
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'avatarId': string;
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Trainer
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface TrainerControllerGet200Response
 */
export interface TrainerControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof TrainerControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof TrainerControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof TrainerControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Trainer>}
     * @memberof TrainerControllerGet200Response
     */
    'content'?: Array<Trainer>;
}
/**
 * 
 * @export
 * @interface TrainerControllerGet200ResponseAllOf
 */
export interface TrainerControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<Trainer>}
     * @memberof TrainerControllerGet200ResponseAllOf
     */
    'content'?: Array<Trainer>;
}
/**
 * 
 * @export
 * @interface UpdateAdminDto
 */
export interface UpdateAdminDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminDto
     */
    'firstNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminDto
     */
    'lastNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminDto
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminDto
     */
    'postCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminDto
     */
    'gender'?: UpdateAdminDtoGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminDto
     */
    'avatarId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAdminDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAdminDto
     */
    'roleIds'?: Array<string>;
}

export const UpdateAdminDtoGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER'
} as const;

export type UpdateAdminDtoGenderEnum = typeof UpdateAdminDtoGenderEnum[keyof typeof UpdateAdminDtoGenderEnum];

/**
 * 
 * @export
 * @interface UpdateCourseDto
 */
export interface UpdateCourseDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCourseDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCourseDto
     */
    'introduce'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCourseDto
     */
    'backgroundId'?: string;
    /**
     * 
     * @type {Array<CreateLessonDto>}
     * @memberof UpdateCourseDto
     */
    'lesson'?: Array<CreateLessonDto>;
}
/**
 * 
 * @export
 * @interface UpdateCustomerCourseDto
 */
export interface UpdateCustomerCourseDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerCourseDto
     */
    'status': UpdateCustomerCourseDtoStatusEnum;
}

export const UpdateCustomerCourseDtoStatusEnum = {
    Normal: 'NORMAL',
    Pending: 'PENDING',
    Accept: 'ACCEPT',
    Cancel: 'CANCEL'
} as const;

export type UpdateCustomerCourseDtoStatusEnum = typeof UpdateCustomerCourseDtoStatusEnum[keyof typeof UpdateCustomerCourseDtoStatusEnum];

/**
 * 
 * @export
 * @interface UpdateCustomerDto
 */
export interface UpdateCustomerDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'firstNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'lastNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'postCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'gender'?: UpdateCustomerDtoGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'avatarId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCustomerDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'backgroundId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'status'?: UpdateCustomerDtoStatusEnum;
}

export const UpdateCustomerDtoGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER'
} as const;

export type UpdateCustomerDtoGenderEnum = typeof UpdateCustomerDtoGenderEnum[keyof typeof UpdateCustomerDtoGenderEnum];
export const UpdateCustomerDtoStatusEnum = {
    Normal: 'NORMAL',
    Pending: 'PENDING',
    Accept: 'ACCEPT',
    Cancel: 'CANCEL'
} as const;

export type UpdateCustomerDtoStatusEnum = typeof UpdateCustomerDtoStatusEnum[keyof typeof UpdateCustomerDtoStatusEnum];

/**
 * 
 * @export
 * @interface UpdateDiscussionsDto
 */
export interface UpdateDiscussionsDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscussionsDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscussionsDto
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscussionsDto
     */
    'timeStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscussionsDto
     */
    'timeEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscussionsDto
     */
    'roomCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscussionsDto
     */
    'roomPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDiscussionsDto
     */
    'roomLink'?: string;
    /**
     * 
     * @type {Array<IdCustomer>}
     * @memberof UpdateDiscussionsDto
     */
    'customers'?: Array<IdCustomer>;
}
/**
 * 
 * @export
 * @interface UpdateDocsDto
 */
export interface UpdateDocsDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDocsDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocsDto
     */
    'content': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDocsDto
     */
    'fileIds': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateFaqDto
 */
export interface UpdateFaqDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateFaqDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFaqDto
     */
    'content': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateFaqDto
     */
    'fileIds': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateFeedbackDto
 */
export interface UpdateFeedbackDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedbackDto
     */
    'level'?: UpdateFeedbackDtoLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedbackDto
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedbackDto
     */
    'userId'?: string;
}

export const UpdateFeedbackDtoLevelEnum = {
    Bad: 'BAD',
    Normal: 'NORMAL',
    Rather: 'RATHER',
    Good: 'GOOD',
    Excellent: 'EXCELLENT'
} as const;

export type UpdateFeedbackDtoLevelEnum = typeof UpdateFeedbackDtoLevelEnum[keyof typeof UpdateFeedbackDtoLevelEnum];

/**
 * 
 * @export
 * @interface UpdateLessonLearnDto
 */
export interface UpdateLessonLearnDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateLessonLearnDto
     */
    'progress'?: number;
}
/**
 * 
 * @export
 * @interface UpdateNewsDto
 */
export interface UpdateNewsDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateNewsDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNewsDto
     */
    'content'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNewsDto
     */
    'isVisible': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateNewsDto
     */
    'avatarId'?: string;
}
/**
 * 
 * @export
 * @interface UpdatePasswordDto
 */
export interface UpdatePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordDto
     */
    'currentPassword': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordDto
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface UpdateRecruitDto
 */
export interface UpdateRecruitDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRecruitDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRecruitDto
     */
    'content'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRecruitDto
     */
    'isVisible': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateRecruitDto
     */
    'avatarId'?: string;
}
/**
 * 
 * @export
 * @interface UpdateRoleDto
 */
export interface UpdateRoleDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleDto
     */
    'code'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRoleDto
     */
    'permissions'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateSurveyQuestionDto
 */
export interface UpdateSurveyQuestionDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSurveyQuestionDto
     */
    'question'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSurveyQuestionDto
     */
    'type'?: UpdateSurveyQuestionDtoTypeEnum;
    /**
     * 
     * @type {Array<CreateSurveyAnswerDto>}
     * @memberof UpdateSurveyQuestionDto
     */
    'answers'?: Array<CreateSurveyAnswerDto>;
}

export const UpdateSurveyQuestionDtoTypeEnum = {
    Option: 'OPTION',
    Text: 'TEXT'
} as const;

export type UpdateSurveyQuestionDtoTypeEnum = typeof UpdateSurveyQuestionDtoTypeEnum[keyof typeof UpdateSurveyQuestionDtoTypeEnum];

/**
 * 
 * @export
 * @interface UpdateTrainerDto
 */
export interface UpdateTrainerDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateTrainerDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTrainerDto
     */
    'firstNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTrainerDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTrainerDto
     */
    'lastNameFurigana'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTrainerDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTrainerDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTrainerDto
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTrainerDto
     */
    'postCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTrainerDto
     */
    'gender'?: UpdateTrainerDtoGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateTrainerDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTrainerDto
     */
    'avatarId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTrainerDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateTrainerDto
     */
    'introduce'?: string;
    /**
     * 
     * @type {Array<CreateWorkoutScheduleDto>}
     * @memberof UpdateTrainerDto
     */
    'workoutSchedule'?: Array<CreateWorkoutScheduleDto>;
}

export const UpdateTrainerDtoGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER'
} as const;

export type UpdateTrainerDtoGenderEnum = typeof UpdateTrainerDtoGenderEnum[keyof typeof UpdateTrainerDtoGenderEnum];

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'identifier': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'verified': boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastLogin': string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'type': string;
    /**
     * 
     * @type {Array<Role>}
     * @memberof User
     */
    'roles': Array<Role>;
    /**
     * 
     * @type {Array<AuthenticationMethod>}
     * @memberof User
     */
    'authenticationMethods': Array<AuthenticationMethod>;
    /**
     * 
     * @type {Array<UserSurveyAnswer>}
     * @memberof User
     */
    'user_survey_answers': Array<UserSurveyAnswer>;
    /**
     * 
     * @type {Array<LessonQuestionAddOnTime>}
     * @memberof User
     */
    'lessonQuestionAddOnTime': Array<LessonQuestionAddOnTime>;
    /**
     * 
     * @type {Trainer}
     * @memberof User
     */
    'trainer'?: Trainer;
    /**
     * 
     * @type {Customer}
     * @memberof User
     */
    'customer'?: Customer;
    /**
     * 
     * @type {Array<Feedback>}
     * @memberof User
     */
    'feedback': Array<Feedback>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface UserAnswerDto
 */
export interface UserAnswerDto {
    /**
     * 
     * @type {string}
     * @memberof UserAnswerDto
     */
    'answer': string;
    /**
     * 
     * @type {string}
     * @memberof UserAnswerDto
     */
    'surveyAnswerId': string;
    /**
     * 
     * @type {string}
     * @memberof UserAnswerDto
     */
    'surveyQuestionId': string;
}
/**
 * 
 * @export
 * @interface UserCreateSurveyAnswerDto
 */
export interface UserCreateSurveyAnswerDto {
    /**
     * 
     * @type {Array<UserAnswerDto>}
     * @memberof UserCreateSurveyAnswerDto
     */
    'userAnswers'?: Array<UserAnswerDto>;
}
/**
 * 
 * @export
 * @interface UserSurveyAnswer
 */
export interface UserSurveyAnswer {
    /**
     * 
     * @type {string}
     * @memberof UserSurveyAnswer
     */
    'answer': string;
    /**
     * 
     * @type {string}
     * @memberof UserSurveyAnswer
     */
    'surveyAnswerId': string;
    /**
     * 
     * @type {string}
     * @memberof UserSurveyAnswer
     */
    'surveyQuestionId': string;
    /**
     * 
     * @type {string}
     * @memberof UserSurveyAnswer
     */
    'userId': string;
    /**
     * 
     * @type {SurveyAnswer}
     * @memberof UserSurveyAnswer
     */
    'surveyAnswer'?: SurveyAnswer;
    /**
     * 
     * @type {SurveyQuestion}
     * @memberof UserSurveyAnswer
     */
    'surveyQuestion': SurveyQuestion;
    /**
     * 
     * @type {User}
     * @memberof UserSurveyAnswer
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof UserSurveyAnswer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserSurveyAnswer
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof UserSurveyAnswer
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof UserSurveyAnswer
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof UserSurveyAnswer
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof UserSurveyAnswer
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface VerifyOtpDto
 */
export interface VerifyOtpDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyOtpDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyOtpDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyOtpDto
     */
    'otpCode': string;
}
/**
 * 
 * @export
 * @interface VerifyTokenDto
 */
export interface VerifyTokenDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyTokenDto
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface WorkoutSchedule
 */
export interface WorkoutSchedule {
    /**
     * 
     * @type {string}
     * @memberof WorkoutSchedule
     */
    'day': string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkoutSchedule
     */
    'status': boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkoutSchedule
     */
    'amFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkoutSchedule
     */
    'amTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkoutSchedule
     */
    'pmFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkoutSchedule
     */
    'pmTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkoutSchedule
     */
    'trainerId'?: string;
    /**
     * 
     * @type {Trainer}
     * @memberof WorkoutSchedule
     */
    'trainer': Trainer;
    /**
     * 
     * @type {string}
     * @memberof WorkoutSchedule
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkoutSchedule
     */
    'createdOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof WorkoutSchedule
     */
    'createdByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkoutSchedule
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {string}
     * @memberof WorkoutSchedule
     */
    'lastModifiedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkoutSchedule
     */
    'deletedAt': string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAdminDto} createAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerCreate: async (createAdminDto: CreateAdminDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAdminDto' is not null or undefined
            assertParamExists('administratorControllerCreate', 'createAdminDto', createAdminDto)
            const localVarPath = `/admin/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdminDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerDelete: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('administratorControllerDelete', 'userId', userId)
            const localVarPath = `/admin/delete/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [isActive] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerGet: async (page: number, sort?: 'DESC' | 'ASC', isActive?: number, size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('administratorControllerGet', 'page', page)
            const localVarPath = `/admin/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerGetByUserId: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('administratorControllerGetByUserId', 'userId', userId)
            const localVarPath = `/admin/get/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateAdminDto} updateAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerUpdate: async (userId: string, updateAdminDto: UpdateAdminDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('administratorControllerUpdate', 'userId', userId)
            // verify required parameter 'updateAdminDto' is not null or undefined
            assertParamExists('administratorControllerUpdate', 'updateAdminDto', updateAdminDto)
            const localVarPath = `/admin/update/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdminDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAdminDto} updateAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerUpdateMe: async (updateAdminDto: UpdateAdminDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAdminDto' is not null or undefined
            assertParamExists('administratorControllerUpdateMe', 'updateAdminDto', updateAdminDto)
            const localVarPath = `/admin/edit/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdminDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAdminDto} createAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorControllerCreate(createAdminDto: CreateAdminDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorControllerCreate(createAdminDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorControllerDelete(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorControllerDelete(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [isActive] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorControllerGet(page: number, sort?: 'DESC' | 'ASC', isActive?: number, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdministratorControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorControllerGet(page, sort, isActive, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorControllerGetByUserId(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Administrator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorControllerGetByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateAdminDto} updateAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorControllerUpdate(userId: string, updateAdminDto: UpdateAdminDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Administrator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorControllerUpdate(userId, updateAdminDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateAdminDto} updateAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorControllerUpdateMe(updateAdminDto: UpdateAdminDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Administrator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorControllerUpdateMe(updateAdminDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAdminDto} createAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerCreate(createAdminDto: CreateAdminDto, options?: any): AxiosPromise<object> {
            return localVarFp.administratorControllerCreate(createAdminDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerDelete(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.administratorControllerDelete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [isActive] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerGet(page: number, sort?: 'DESC' | 'ASC', isActive?: number, size?: number, fullTextSearch?: string, options?: any): AxiosPromise<AdministratorControllerGet200Response> {
            return localVarFp.administratorControllerGet(page, sort, isActive, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerGetByUserId(userId: string, options?: any): AxiosPromise<Administrator> {
            return localVarFp.administratorControllerGetByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateAdminDto} updateAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerUpdate(userId: string, updateAdminDto: UpdateAdminDto, options?: any): AxiosPromise<Administrator> {
            return localVarFp.administratorControllerUpdate(userId, updateAdminDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAdminDto} updateAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorControllerUpdateMe(updateAdminDto: UpdateAdminDto, options?: any): AxiosPromise<Administrator> {
            return localVarFp.administratorControllerUpdateMe(updateAdminDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @param {CreateAdminDto} createAdminDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public administratorControllerCreate(createAdminDto: CreateAdminDto, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).administratorControllerCreate(createAdminDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public administratorControllerDelete(userId: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).administratorControllerDelete(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {'DESC' | 'ASC'} [sort] 
     * @param {number} [isActive] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public administratorControllerGet(page: number, sort?: 'DESC' | 'ASC', isActive?: number, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).administratorControllerGet(page, sort, isActive, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public administratorControllerGetByUserId(userId: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).administratorControllerGetByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {UpdateAdminDto} updateAdminDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public administratorControllerUpdate(userId: string, updateAdminDto: UpdateAdminDto, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).administratorControllerUpdate(userId, updateAdminDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAdminDto} updateAdminDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public administratorControllerUpdateMe(updateAdminDto: UpdateAdminDto, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).administratorControllerUpdateMe(updateAdminDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFolderDto} createFolderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerCreateFolder: async (createFolderDto: CreateFolderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFolderDto' is not null or undefined
            assertParamExists('assetControllerCreateFolder', 'createFolderDto', createFolderDto)
            const localVarPath = `/assets/create-folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFolderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assetControllerDelete', 'id', id)
            const localVarPath = `/assets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerDiscover: async (folderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('assetControllerDiscover', 'folderId', folderId)
            const localVarPath = `/assets/{folderId}/discover`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerGetByPath: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assets/get-by-path/{path}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {string} [assetFolderId] 
         * @param {string} [s3FilePath] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerUploadFile: async (file: File, assetFolderId?: string, s3FilePath?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('assetControllerUploadFile', 'file', file)
            const localVarPath = `/assets/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (assetFolderId !== undefined) { 
                localVarFormParams.append('assetFolderId', assetFolderId as any);
            }
    
            if (s3FilePath !== undefined) { 
                localVarFormParams.append('s3FilePath', s3FilePath as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateFolderDto} createFolderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetControllerCreateFolder(createFolderDto: CreateFolderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetControllerCreateFolder(createFolderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetControllerDiscover(folderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Asset>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetControllerDiscover(folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetControllerGetByPath(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetControllerGetByPath(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {string} [assetFolderId] 
         * @param {string} [s3FilePath] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetControllerUploadFile(file: File, assetFolderId?: string, s3FilePath?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetControllerUploadFile(file, assetFolderId, s3FilePath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateFolderDto} createFolderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerCreateFolder(createFolderDto: CreateFolderDto, options?: any): AxiosPromise<object> {
            return localVarFp.assetControllerCreateFolder(createFolderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.assetControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerDiscover(folderId: string, options?: any): AxiosPromise<Array<Asset>> {
            return localVarFp.assetControllerDiscover(folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerGetByPath(options?: any): AxiosPromise<void> {
            return localVarFp.assetControllerGetByPath(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {string} [assetFolderId] 
         * @param {string} [s3FilePath] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerUploadFile(file: File, assetFolderId?: string, s3FilePath?: string, options?: any): AxiosPromise<object> {
            return localVarFp.assetControllerUploadFile(file, assetFolderId, s3FilePath, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI {
    /**
     * 
     * @param {CreateFolderDto} createFolderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetControllerCreateFolder(createFolderDto: CreateFolderDto, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetControllerCreateFolder(createFolderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetControllerDelete(id: string, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetControllerDiscover(folderId: string, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetControllerDiscover(folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetControllerGetByPath(options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetControllerGetByPath(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {string} [assetFolderId] 
     * @param {string} [s3FilePath] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetControllerUploadFile(file: File, assetFolderId?: string, s3FilePath?: string, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetControllerUploadFile(file, assetFolderId, s3FilePath, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAdminLogin: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerAdminLogin', 'loginDto', loginDto)
            const localVarPath = `/auth/admin/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAdminMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/admin/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckUsername} checkUsername 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCheckUsernameForgot: async (checkUsername: CheckUsername, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkUsername' is not null or undefined
            assertParamExists('authControllerCheckUsernameForgot', 'checkUsername', checkUsername)
            const localVarPath = `/auth/forgot/check-username`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkUsername, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckUsername} checkUsername 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCheckUsernameRegister: async (checkUsername: CheckUsername, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkUsername' is not null or undefined
            assertParamExists('authControllerCheckUsernameRegister', 'checkUsername', checkUsername)
            const localVarPath = `/auth/register/check-username`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkUsername, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCustomerLogin: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerCustomerLogin', 'loginDto', loginDto)
            const localVarPath = `/auth/customer/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCustomerMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/customer/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordDto} forgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerForgotPassword: async (forgotPasswordDto: ForgotPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordDto' is not null or undefined
            assertParamExists('authControllerForgotPassword', 'forgotPasswordDto', forgotPasswordDto)
            const localVarPath = `/auth/forgot/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterCustomerDto} registerCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRegisterCustomerAccount: async (registerCustomerDto: RegisterCustomerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerCustomerDto' is not null or undefined
            assertParamExists('authControllerRegisterCustomerAccount', 'registerCustomerDto', registerCustomerDto)
            const localVarPath = `/auth/customer/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerCustomerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTrainerLogin: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerTrainerLogin', 'loginDto', loginDto)
            const localVarPath = `/auth/trainer/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTrainerMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/trainer/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePasswordDto} updatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerUpdatePassword: async (updatePasswordDto: UpdatePasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePasswordDto' is not null or undefined
            assertParamExists('authControllerUpdatePassword', 'updatePasswordDto', updatePasswordDto)
            const localVarPath = `/auth/update/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyTokenDto} verifyTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyToken: async (verifyTokenDto: VerifyTokenDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyTokenDto' is not null or undefined
            assertParamExists('authControllerVerifyToken', 'verifyTokenDto', verifyTokenDto)
            const localVarPath = `/auth/verify-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerAdminLogin(loginDto: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerAdminLogin(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerAdminMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Administrator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerAdminMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CheckUsername} checkUsername 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerCheckUsernameForgot(checkUsername: CheckUsername, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerCheckUsernameForgot(checkUsername, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CheckUsername} checkUsername 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerCheckUsernameRegister(checkUsername: CheckUsername, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerCheckUsernameRegister(checkUsername, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerCustomerLogin(loginDto: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerCustomerLogin(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerCustomerMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerCustomerMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ForgotPasswordDto} forgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerForgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerForgotPassword(forgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterCustomerDto} registerCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRegisterCustomerAccount(registerCustomerDto: RegisterCustomerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRegisterCustomerAccount(registerCustomerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerTrainerLogin(loginDto: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerTrainerLogin(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerTrainerMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerTrainerMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdatePasswordDto} updatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerUpdatePassword(updatePasswordDto: UpdatePasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerUpdatePassword(updatePasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyTokenDto} verifyTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerVerifyToken(verifyTokenDto: VerifyTokenDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerVerifyToken(verifyTokenDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAdminLogin(loginDto: LoginDto, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.authControllerAdminLogin(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAdminMe(options?: any): AxiosPromise<Administrator> {
            return localVarFp.authControllerAdminMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckUsername} checkUsername 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCheckUsernameForgot(checkUsername: CheckUsername, options?: any): AxiosPromise<User> {
            return localVarFp.authControllerCheckUsernameForgot(checkUsername, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckUsername} checkUsername 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCheckUsernameRegister(checkUsername: CheckUsername, options?: any): AxiosPromise<boolean> {
            return localVarFp.authControllerCheckUsernameRegister(checkUsername, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCustomerLogin(loginDto: LoginDto, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.authControllerCustomerLogin(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCustomerMe(options?: any): AxiosPromise<Customer> {
            return localVarFp.authControllerCustomerMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordDto} forgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerForgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerForgotPassword(forgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterCustomerDto} registerCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRegisterCustomerAccount(registerCustomerDto: RegisterCustomerDto, options?: any): AxiosPromise<object> {
            return localVarFp.authControllerRegisterCustomerAccount(registerCustomerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTrainerLogin(loginDto: LoginDto, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.authControllerTrainerLogin(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTrainerMe(options?: any): AxiosPromise<void> {
            return localVarFp.authControllerTrainerMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePasswordDto} updatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerUpdatePassword(updatePasswordDto: UpdatePasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerUpdatePassword(updatePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyTokenDto} verifyTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyToken(verifyTokenDto: VerifyTokenDto, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.authControllerVerifyToken(verifyTokenDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Login
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerAdminLogin(loginDto: LoginDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerAdminLogin(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerAdminMe(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerAdminMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CheckUsername} checkUsername 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerCheckUsernameForgot(checkUsername: CheckUsername, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerCheckUsernameForgot(checkUsername, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CheckUsername} checkUsername 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerCheckUsernameRegister(checkUsername: CheckUsername, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerCheckUsernameRegister(checkUsername, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerCustomerLogin(loginDto: LoginDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerCustomerLogin(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerCustomerMe(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerCustomerMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgotPasswordDto} forgotPasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerForgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerForgotPassword(forgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterCustomerDto} registerCustomerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerRegisterCustomerAccount(registerCustomerDto: RegisterCustomerDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerRegisterCustomerAccount(registerCustomerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerTrainerLogin(loginDto: LoginDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerTrainerLogin(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerTrainerMe(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerTrainerMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePasswordDto} updatePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerUpdatePassword(updatePasswordDto: UpdatePasswordDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerUpdatePassword(updatePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyTokenDto} verifyTokenDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerVerifyToken(verifyTokenDto: VerifyTokenDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerVerifyToken(verifyTokenDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CourseApi - axios parameter creator
 * @export
 */
export const CourseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCourseDto} createCourseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerCreate: async (createCourseDto: CreateCourseDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCourseDto' is not null or undefined
            assertParamExists('courseControllerCreate', 'createCourseDto', createCourseDto)
            const localVarPath = `/course/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCourseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('courseControllerDelete', 'id', id)
            const localVarPath = `/course/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {string} [userId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerGet: async (page: number, sort?: 'DESC' | 'ASC', userId?: string, startDate?: string, endDate?: string, size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('courseControllerGet', 'page', page)
            const localVarPath = `/course/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerGetById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('courseControllerGetById', 'id', id)
            const localVarPath = `/course/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerGetByUserId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/course/get/learning/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCourseDto} updateCourseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerUpdate: async (id: string, updateCourseDto: UpdateCourseDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('courseControllerUpdate', 'id', id)
            // verify required parameter 'updateCourseDto' is not null or undefined
            assertParamExists('courseControllerUpdate', 'updateCourseDto', updateCourseDto)
            const localVarPath = `/course/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCourseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseApi - functional programming interface
 * @export
 */
export const CourseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CourseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCourseDto} createCourseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseControllerCreate(createCourseDto: CreateCourseDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseControllerCreate(createCourseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {string} [userId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseControllerGet(page: number, sort?: 'DESC' | 'ASC', userId?: string, startDate?: string, endDate?: string, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseControllerGet(page, sort, userId, startDate, endDate, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseControllerGetById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Course>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseControllerGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseControllerGetByUserId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseControllerGetByUserId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCourseDto} updateCourseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseControllerUpdate(id: string, updateCourseDto: UpdateCourseDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseControllerUpdate(id, updateCourseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CourseApi - factory interface
 * @export
 */
export const CourseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CourseApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCourseDto} createCourseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerCreate(createCourseDto: CreateCourseDto, options?: any): AxiosPromise<object> {
            return localVarFp.courseControllerCreate(createCourseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.courseControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {string} [userId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerGet(page: number, sort?: 'DESC' | 'ASC', userId?: string, startDate?: string, endDate?: string, size?: number, fullTextSearch?: string, options?: any): AxiosPromise<CourseControllerGet200Response> {
            return localVarFp.courseControllerGet(page, sort, userId, startDate, endDate, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerGetById(id: string, options?: any): AxiosPromise<Course> {
            return localVarFp.courseControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerGetByUserId(options?: any): AxiosPromise<CourseControllerGet200Response> {
            return localVarFp.courseControllerGetByUserId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCourseDto} updateCourseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseControllerUpdate(id: string, updateCourseDto: UpdateCourseDto, options?: any): AxiosPromise<object> {
            return localVarFp.courseControllerUpdate(id, updateCourseDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CourseApi - object-oriented interface
 * @export
 * @class CourseApi
 * @extends {BaseAPI}
 */
export class CourseApi extends BaseAPI {
    /**
     * 
     * @param {CreateCourseDto} createCourseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseControllerCreate(createCourseDto: CreateCourseDto, options?: AxiosRequestConfig) {
        return CourseApiFp(this.configuration).courseControllerCreate(createCourseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseControllerDelete(id: string, options?: AxiosRequestConfig) {
        return CourseApiFp(this.configuration).courseControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {'DESC' | 'ASC'} [sort] 
     * @param {string} [userId] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseControllerGet(page: number, sort?: 'DESC' | 'ASC', userId?: string, startDate?: string, endDate?: string, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return CourseApiFp(this.configuration).courseControllerGet(page, sort, userId, startDate, endDate, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseControllerGetById(id: string, options?: AxiosRequestConfig) {
        return CourseApiFp(this.configuration).courseControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseControllerGetByUserId(options?: AxiosRequestConfig) {
        return CourseApiFp(this.configuration).courseControllerGetByUserId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateCourseDto} updateCourseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseControllerUpdate(id: string, updateCourseDto: UpdateCourseDto, options?: AxiosRequestConfig) {
        return CourseApiFp(this.configuration).courseControllerUpdate(id, updateCourseDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerCourseApi - axios parameter creator
 * @export
 */
export const CustomerCourseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {UpdateCustomerCourseDto} updateCustomerCourseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCourseControllerAccept: async (customerId: string, updateCustomerCourseDto: UpdateCustomerCourseDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerCourseControllerAccept', 'customerId', customerId)
            // verify required parameter 'updateCustomerCourseDto' is not null or undefined
            assertParamExists('customerCourseControllerAccept', 'updateCustomerCourseDto', updateCustomerCourseDto)
            const localVarPath = `/customer-course/accept/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerCourseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [isActive] 
         * @param {Array<'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'>} [status] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCourseControllerGet: async (page: number, sort?: 'DESC' | 'ASC', isActive?: number, status?: Array<'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'>, size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('customerCourseControllerGet', 'page', page)
            const localVarPath = `/customer-course/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {Array<'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'>} [status] 
         * @param {number} [isActive] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCourseControllerGetCustomer: async (page: number, sort?: 'DESC' | 'ASC', status?: Array<'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'>, isActive?: number, size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('customerCourseControllerGetCustomer', 'page', page)
            const localVarPath = `/customer-course/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCustomerCourseDto} updateCustomerCourseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCourseControllerUpdate: async (id: string, updateCustomerCourseDto: UpdateCustomerCourseDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('customerCourseControllerUpdate', 'id', id)
            // verify required parameter 'updateCustomerCourseDto' is not null or undefined
            assertParamExists('customerCourseControllerUpdate', 'updateCustomerCourseDto', updateCustomerCourseDto)
            const localVarPath = `/customer-course/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerCourseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerCourseApi - functional programming interface
 * @export
 */
export const CustomerCourseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerCourseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {UpdateCustomerCourseDto} updateCustomerCourseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerCourseControllerAccept(customerId: string, updateCustomerCourseDto: UpdateCustomerCourseDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCourseControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerCourseControllerAccept(customerId, updateCustomerCourseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [isActive] 
         * @param {Array<'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'>} [status] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerCourseControllerGet(page: number, sort?: 'DESC' | 'ASC', isActive?: number, status?: Array<'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'>, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCourseControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerCourseControllerGet(page, sort, isActive, status, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {Array<'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'>} [status] 
         * @param {number} [isActive] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerCourseControllerGetCustomer(page: number, sort?: 'DESC' | 'ASC', status?: Array<'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'>, isActive?: number, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCourseControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerCourseControllerGetCustomer(page, sort, status, isActive, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCustomerCourseDto} updateCustomerCourseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerCourseControllerUpdate(id: string, updateCustomerCourseDto: UpdateCustomerCourseDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCourseControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerCourseControllerUpdate(id, updateCustomerCourseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerCourseApi - factory interface
 * @export
 */
export const CustomerCourseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerCourseApiFp(configuration)
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {UpdateCustomerCourseDto} updateCustomerCourseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCourseControllerAccept(customerId: string, updateCustomerCourseDto: UpdateCustomerCourseDto, options?: any): AxiosPromise<CustomerCourseControllerGet200Response> {
            return localVarFp.customerCourseControllerAccept(customerId, updateCustomerCourseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [isActive] 
         * @param {Array<'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'>} [status] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCourseControllerGet(page: number, sort?: 'DESC' | 'ASC', isActive?: number, status?: Array<'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'>, size?: number, fullTextSearch?: string, options?: any): AxiosPromise<CustomerCourseControllerGet200Response> {
            return localVarFp.customerCourseControllerGet(page, sort, isActive, status, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {Array<'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'>} [status] 
         * @param {number} [isActive] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCourseControllerGetCustomer(page: number, sort?: 'DESC' | 'ASC', status?: Array<'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'>, isActive?: number, size?: number, fullTextSearch?: string, options?: any): AxiosPromise<CustomerCourseControllerGet200Response> {
            return localVarFp.customerCourseControllerGetCustomer(page, sort, status, isActive, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCustomerCourseDto} updateCustomerCourseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerCourseControllerUpdate(id: string, updateCustomerCourseDto: UpdateCustomerCourseDto, options?: any): AxiosPromise<CustomerCourseControllerGet200Response> {
            return localVarFp.customerCourseControllerUpdate(id, updateCustomerCourseDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerCourseApi - object-oriented interface
 * @export
 * @class CustomerCourseApi
 * @extends {BaseAPI}
 */
export class CustomerCourseApi extends BaseAPI {
    /**
     * 
     * @param {string} customerId 
     * @param {UpdateCustomerCourseDto} updateCustomerCourseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCourseApi
     */
    public customerCourseControllerAccept(customerId: string, updateCustomerCourseDto: UpdateCustomerCourseDto, options?: AxiosRequestConfig) {
        return CustomerCourseApiFp(this.configuration).customerCourseControllerAccept(customerId, updateCustomerCourseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {'DESC' | 'ASC'} [sort] 
     * @param {number} [isActive] 
     * @param {Array<'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'>} [status] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCourseApi
     */
    public customerCourseControllerGet(page: number, sort?: 'DESC' | 'ASC', isActive?: number, status?: Array<'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'>, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return CustomerCourseApiFp(this.configuration).customerCourseControllerGet(page, sort, isActive, status, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {'DESC' | 'ASC'} [sort] 
     * @param {Array<'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'>} [status] 
     * @param {number} [isActive] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCourseApi
     */
    public customerCourseControllerGetCustomer(page: number, sort?: 'DESC' | 'ASC', status?: Array<'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'>, isActive?: number, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return CustomerCourseApiFp(this.configuration).customerCourseControllerGetCustomer(page, sort, status, isActive, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateCustomerCourseDto} updateCustomerCourseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCourseApi
     */
    public customerCourseControllerUpdate(id: string, updateCustomerCourseDto: UpdateCustomerCourseDto, options?: AxiosRequestConfig) {
        return CustomerCourseApiFp(this.configuration).customerCourseControllerUpdate(id, updateCustomerCourseDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCustomerDto} createCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerCreate: async (createCustomerDto: CreateCustomerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCustomerDto' is not null or undefined
            assertParamExists('customerControllerCreate', 'createCustomerDto', createCustomerDto)
            const localVarPath = `/customers/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerDelete: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('customerControllerDelete', 'userId', userId)
            const localVarPath = `/customers/delete/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'} [status] 
         * @param {number} [isActive] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGet: async (page: number, sort?: 'DESC' | 'ASC', status?: 'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL', isActive?: number, size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('customerControllerGet', 'page', page)
            const localVarPath = `/customers/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetById: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('customerControllerGetById', 'userId', userId)
            const localVarPath = `/customers/get/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateCustomerDto} updateCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerUpdate: async (userId: string, updateCustomerDto: UpdateCustomerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('customerControllerUpdate', 'userId', userId)
            // verify required parameter 'updateCustomerDto' is not null or undefined
            assertParamExists('customerControllerUpdate', 'updateCustomerDto', updateCustomerDto)
            const localVarPath = `/customers/update/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCustomerDto} updateCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerUpdateMe: async (updateCustomerDto: UpdateCustomerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCustomerDto' is not null or undefined
            assertParamExists('customerControllerUpdateMe', 'updateCustomerDto', updateCustomerDto)
            const localVarPath = `/customers/edit/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCustomerDto} createCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerCreate(createCustomerDto: CreateCustomerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerCreate(createCustomerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerDelete(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerDelete(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'} [status] 
         * @param {number} [isActive] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGet(page: number, sort?: 'DESC' | 'ASC', status?: 'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL', isActive?: number, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerGet(page, sort, status, isActive, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetById(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerGetById(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateCustomerDto} updateCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerUpdate(userId: string, updateCustomerDto: UpdateCustomerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerUpdate(userId, updateCustomerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateCustomerDto} updateCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerUpdateMe(updateCustomerDto: UpdateCustomerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerUpdateMe(updateCustomerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCustomerDto} createCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerCreate(createCustomerDto: CreateCustomerDto, options?: any): AxiosPromise<object> {
            return localVarFp.customerControllerCreate(createCustomerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerDelete(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.customerControllerDelete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'} [status] 
         * @param {number} [isActive] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGet(page: number, sort?: 'DESC' | 'ASC', status?: 'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL', isActive?: number, size?: number, fullTextSearch?: string, options?: any): AxiosPromise<CustomerControllerGet200Response> {
            return localVarFp.customerControllerGet(page, sort, status, isActive, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetById(userId: string, options?: any): AxiosPromise<Customer> {
            return localVarFp.customerControllerGetById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateCustomerDto} updateCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerUpdate(userId: string, updateCustomerDto: UpdateCustomerDto, options?: any): AxiosPromise<Customer> {
            return localVarFp.customerControllerUpdate(userId, updateCustomerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCustomerDto} updateCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerUpdateMe(updateCustomerDto: UpdateCustomerDto, options?: any): AxiosPromise<Customer> {
            return localVarFp.customerControllerUpdateMe(updateCustomerDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
    /**
     * 
     * @param {CreateCustomerDto} createCustomerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerCreate(createCustomerDto: CreateCustomerDto, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerCreate(createCustomerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerDelete(userId: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerDelete(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {'DESC' | 'ASC'} [sort] 
     * @param {'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL'} [status] 
     * @param {number} [isActive] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerGet(page: number, sort?: 'DESC' | 'ASC', status?: 'NORMAL' | 'PENDING' | 'ACCEPT' | 'CANCEL', isActive?: number, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerGet(page, sort, status, isActive, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerGetById(userId: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerGetById(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {UpdateCustomerDto} updateCustomerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerUpdate(userId: string, updateCustomerDto: UpdateCustomerDto, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerUpdate(userId, updateCustomerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCustomerDto} updateCustomerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerUpdateMe(updateCustomerDto: UpdateCustomerDto, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerUpdateMe(updateCustomerDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DiscussionsApi - axios parameter creator
 * @export
 */
export const DiscussionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatediscussionsDto} creatediscussionsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discussionsControllerCreate: async (creatediscussionsDto: CreatediscussionsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creatediscussionsDto' is not null or undefined
            assertParamExists('discussionsControllerCreate', 'creatediscussionsDto', creatediscussionsDto)
            const localVarPath = `/discussions/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(creatediscussionsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discussionsControllerDetele: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('discussionsControllerDetele', 'id', id)
            const localVarPath = `/discussions/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discussionsControllerGetByLessonId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/discussions/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDiscussionsDto} updateDiscussionsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discussionsControllerUpdate: async (id: string, updateDiscussionsDto: UpdateDiscussionsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('discussionsControllerUpdate', 'id', id)
            // verify required parameter 'updateDiscussionsDto' is not null or undefined
            assertParamExists('discussionsControllerUpdate', 'updateDiscussionsDto', updateDiscussionsDto)
            const localVarPath = `/discussions/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDiscussionsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscussionsApi - functional programming interface
 * @export
 */
export const DiscussionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiscussionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatediscussionsDto} creatediscussionsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discussionsControllerCreate(creatediscussionsDto: CreatediscussionsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discussionsControllerCreate(creatediscussionsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discussionsControllerDetele(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discussionsControllerDetele(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discussionsControllerGetByLessonId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Discussions>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discussionsControllerGetByLessonId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDiscussionsDto} updateDiscussionsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discussionsControllerUpdate(id: string, updateDiscussionsDto: UpdateDiscussionsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discussionsControllerUpdate(id, updateDiscussionsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DiscussionsApi - factory interface
 * @export
 */
export const DiscussionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiscussionsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatediscussionsDto} creatediscussionsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discussionsControllerCreate(creatediscussionsDto: CreatediscussionsDto, options?: any): AxiosPromise<object> {
            return localVarFp.discussionsControllerCreate(creatediscussionsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discussionsControllerDetele(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.discussionsControllerDetele(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discussionsControllerGetByLessonId(options?: any): AxiosPromise<Array<Discussions>> {
            return localVarFp.discussionsControllerGetByLessonId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDiscussionsDto} updateDiscussionsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discussionsControllerUpdate(id: string, updateDiscussionsDto: UpdateDiscussionsDto, options?: any): AxiosPromise<object> {
            return localVarFp.discussionsControllerUpdate(id, updateDiscussionsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiscussionsApi - object-oriented interface
 * @export
 * @class DiscussionsApi
 * @extends {BaseAPI}
 */
export class DiscussionsApi extends BaseAPI {
    /**
     * 
     * @param {CreatediscussionsDto} creatediscussionsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionsApi
     */
    public discussionsControllerCreate(creatediscussionsDto: CreatediscussionsDto, options?: AxiosRequestConfig) {
        return DiscussionsApiFp(this.configuration).discussionsControllerCreate(creatediscussionsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionsApi
     */
    public discussionsControllerDetele(id: string, options?: AxiosRequestConfig) {
        return DiscussionsApiFp(this.configuration).discussionsControllerDetele(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionsApi
     */
    public discussionsControllerGetByLessonId(options?: AxiosRequestConfig) {
        return DiscussionsApiFp(this.configuration).discussionsControllerGetByLessonId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateDiscussionsDto} updateDiscussionsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscussionsApi
     */
    public discussionsControllerUpdate(id: string, updateDiscussionsDto: UpdateDiscussionsDto, options?: AxiosRequestConfig) {
        return DiscussionsApiFp(this.configuration).discussionsControllerUpdate(id, updateDiscussionsDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocsApi - axios parameter creator
 * @export
 */
export const DocsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDocsDto} createDocsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsControllerCreateDocs: async (createDocsDto: CreateDocsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDocsDto' is not null or undefined
            assertParamExists('docsControllerCreateDocs', 'createDocsDto', createDocsDto)
            const localVarPath = `/docs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDocsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsControllerDeleteDocs: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('docsControllerDeleteDocs', 'id', id)
            const localVarPath = `/docs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {boolean} [isVisible] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsControllerGet: async (page: number, sort?: 'DESC' | 'ASC', isVisible?: boolean, size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('docsControllerGet', 'page', page)
            const localVarPath = `/docs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (isVisible !== undefined) {
                localVarQueryParameter['isVisible'] = isVisible;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsControllerGetDetail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('docsControllerGetDetail', 'id', id)
            const localVarPath = `/docs/get-by-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDocsDto} updateDocsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsControllerUpdateDocs: async (id: string, updateDocsDto: UpdateDocsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('docsControllerUpdateDocs', 'id', id)
            // verify required parameter 'updateDocsDto' is not null or undefined
            assertParamExists('docsControllerUpdateDocs', 'updateDocsDto', updateDocsDto)
            const localVarPath = `/docs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocsApi - functional programming interface
 * @export
 */
export const DocsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDocsDto} createDocsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async docsControllerCreateDocs(createDocsDto: CreateDocsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Help>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.docsControllerCreateDocs(createDocsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async docsControllerDeleteDocs(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.docsControllerDeleteDocs(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {boolean} [isVisible] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async docsControllerGet(page: number, sort?: 'DESC' | 'ASC', isVisible?: boolean, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocsControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.docsControllerGet(page, sort, isVisible, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async docsControllerGetDetail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Help>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.docsControllerGetDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDocsDto} updateDocsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async docsControllerUpdateDocs(id: string, updateDocsDto: UpdateDocsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Help>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.docsControllerUpdateDocs(id, updateDocsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocsApi - factory interface
 * @export
 */
export const DocsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateDocsDto} createDocsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsControllerCreateDocs(createDocsDto: CreateDocsDto, options?: any): AxiosPromise<Help> {
            return localVarFp.docsControllerCreateDocs(createDocsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsControllerDeleteDocs(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.docsControllerDeleteDocs(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {boolean} [isVisible] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsControllerGet(page: number, sort?: 'DESC' | 'ASC', isVisible?: boolean, size?: number, fullTextSearch?: string, options?: any): AxiosPromise<DocsControllerGet200Response> {
            return localVarFp.docsControllerGet(page, sort, isVisible, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsControllerGetDetail(id: string, options?: any): AxiosPromise<Help> {
            return localVarFp.docsControllerGetDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDocsDto} updateDocsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsControllerUpdateDocs(id: string, updateDocsDto: UpdateDocsDto, options?: any): AxiosPromise<Help> {
            return localVarFp.docsControllerUpdateDocs(id, updateDocsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocsApi - object-oriented interface
 * @export
 * @class DocsApi
 * @extends {BaseAPI}
 */
export class DocsApi extends BaseAPI {
    /**
     * 
     * @param {CreateDocsDto} createDocsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public docsControllerCreateDocs(createDocsDto: CreateDocsDto, options?: AxiosRequestConfig) {
        return DocsApiFp(this.configuration).docsControllerCreateDocs(createDocsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public docsControllerDeleteDocs(id: string, options?: AxiosRequestConfig) {
        return DocsApiFp(this.configuration).docsControllerDeleteDocs(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {'DESC' | 'ASC'} [sort] 
     * @param {boolean} [isVisible] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public docsControllerGet(page: number, sort?: 'DESC' | 'ASC', isVisible?: boolean, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return DocsApiFp(this.configuration).docsControllerGet(page, sort, isVisible, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public docsControllerGetDetail(id: string, options?: AxiosRequestConfig) {
        return DocsApiFp(this.configuration).docsControllerGetDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateDocsDto} updateDocsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocsApi
     */
    public docsControllerUpdateDocs(id: string, updateDocsDto: UpdateDocsDto, options?: AxiosRequestConfig) {
        return DocsApiFp(this.configuration).docsControllerUpdateDocs(id, updateDocsDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FaqApi - axios parameter creator
 * @export
 */
export const FaqApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFaqDto} createFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqControllerCreateFaq: async (createFaqDto: CreateFaqDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFaqDto' is not null or undefined
            assertParamExists('faqControllerCreateFaq', 'createFaqDto', createFaqDto)
            const localVarPath = `/faq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFaqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqControllerDeleteFaq: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('faqControllerDeleteFaq', 'id', id)
            const localVarPath = `/faq/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqControllerGet: async (page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('faqControllerGet', 'page', page)
            const localVarPath = `/faq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqControllerGetDetail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('faqControllerGetDetail', 'id', id)
            const localVarPath = `/faq/get-by-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateFaqDto} updateFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqControllerUpdateFaq: async (id: string, updateFaqDto: UpdateFaqDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('faqControllerUpdateFaq', 'id', id)
            // verify required parameter 'updateFaqDto' is not null or undefined
            assertParamExists('faqControllerUpdateFaq', 'updateFaqDto', updateFaqDto)
            const localVarPath = `/faq/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFaqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FaqApi - functional programming interface
 * @export
 */
export const FaqApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FaqApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateFaqDto} createFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqControllerCreateFaq(createFaqDto: CreateFaqDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Help>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqControllerCreateFaq(createFaqDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqControllerDeleteFaq(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqControllerDeleteFaq(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqControllerGet(page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocsControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqControllerGet(page, sort, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqControllerGetDetail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Help>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqControllerGetDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateFaqDto} updateFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqControllerUpdateFaq(id: string, updateFaqDto: UpdateFaqDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Help>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqControllerUpdateFaq(id, updateFaqDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FaqApi - factory interface
 * @export
 */
export const FaqApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FaqApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateFaqDto} createFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqControllerCreateFaq(createFaqDto: CreateFaqDto, options?: any): AxiosPromise<Help> {
            return localVarFp.faqControllerCreateFaq(createFaqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqControllerDeleteFaq(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.faqControllerDeleteFaq(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqControllerGet(page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options?: any): AxiosPromise<DocsControllerGet200Response> {
            return localVarFp.faqControllerGet(page, sort, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqControllerGetDetail(id: string, options?: any): AxiosPromise<Help> {
            return localVarFp.faqControllerGetDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateFaqDto} updateFaqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqControllerUpdateFaq(id: string, updateFaqDto: UpdateFaqDto, options?: any): AxiosPromise<Help> {
            return localVarFp.faqControllerUpdateFaq(id, updateFaqDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FaqApi - object-oriented interface
 * @export
 * @class FaqApi
 * @extends {BaseAPI}
 */
export class FaqApi extends BaseAPI {
    /**
     * 
     * @param {CreateFaqDto} createFaqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    public faqControllerCreateFaq(createFaqDto: CreateFaqDto, options?: AxiosRequestConfig) {
        return FaqApiFp(this.configuration).faqControllerCreateFaq(createFaqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    public faqControllerDeleteFaq(id: string, options?: AxiosRequestConfig) {
        return FaqApiFp(this.configuration).faqControllerDeleteFaq(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {'DESC' | 'ASC'} [sort] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    public faqControllerGet(page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return FaqApiFp(this.configuration).faqControllerGet(page, sort, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    public faqControllerGetDetail(id: string, options?: AxiosRequestConfig) {
        return FaqApiFp(this.configuration).faqControllerGetDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateFaqDto} updateFaqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    public faqControllerUpdateFaq(id: string, updateFaqDto: UpdateFaqDto, options?: AxiosRequestConfig) {
        return FaqApiFp(this.configuration).faqControllerUpdateFaq(id, updateFaqDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeedbackApi - axios parameter creator
 * @export
 */
export const FeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFeedbackDto} createFeedbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackControllerCreate: async (createFeedbackDto: CreateFeedbackDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFeedbackDto' is not null or undefined
            assertParamExists('feedbackControllerCreate', 'createFeedbackDto', createFeedbackDto)
            const localVarPath = `/feedback/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFeedbackDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('feedbackControllerDelete', 'id', id)
            const localVarPath = `/feedback/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {string} [userId] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackControllerGet: async (page: number, sort?: 'DESC' | 'ASC', userId?: string, size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('feedbackControllerGet', 'page', page)
            const localVarPath = `/feedback/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateFeedbackDto} updateFeedbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackControllerUpdate: async (id: string, updateFeedbackDto: UpdateFeedbackDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('feedbackControllerUpdate', 'id', id)
            // verify required parameter 'updateFeedbackDto' is not null or undefined
            assertParamExists('feedbackControllerUpdate', 'updateFeedbackDto', updateFeedbackDto)
            const localVarPath = `/feedback/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFeedbackDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedbackApi - functional programming interface
 * @export
 */
export const FeedbackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedbackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateFeedbackDto} createFeedbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackControllerCreate(createFeedbackDto: CreateFeedbackDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackControllerCreate(createFeedbackDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {string} [userId] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackControllerGet(page: number, sort?: 'DESC' | 'ASC', userId?: string, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LessonControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackControllerGet(page, sort, userId, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateFeedbackDto} updateFeedbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackControllerUpdate(id: string, updateFeedbackDto: UpdateFeedbackDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackControllerUpdate(id, updateFeedbackDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedbackApi - factory interface
 * @export
 */
export const FeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedbackApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateFeedbackDto} createFeedbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackControllerCreate(createFeedbackDto: CreateFeedbackDto, options?: any): AxiosPromise<object> {
            return localVarFp.feedbackControllerCreate(createFeedbackDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.feedbackControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {string} [userId] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackControllerGet(page: number, sort?: 'DESC' | 'ASC', userId?: string, size?: number, fullTextSearch?: string, options?: any): AxiosPromise<LessonControllerGet200Response> {
            return localVarFp.feedbackControllerGet(page, sort, userId, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateFeedbackDto} updateFeedbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackControllerUpdate(id: string, updateFeedbackDto: UpdateFeedbackDto, options?: any): AxiosPromise<Feedback> {
            return localVarFp.feedbackControllerUpdate(id, updateFeedbackDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedbackApi - object-oriented interface
 * @export
 * @class FeedbackApi
 * @extends {BaseAPI}
 */
export class FeedbackApi extends BaseAPI {
    /**
     * 
     * @param {CreateFeedbackDto} createFeedbackDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackControllerCreate(createFeedbackDto: CreateFeedbackDto, options?: AxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).feedbackControllerCreate(createFeedbackDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackControllerDelete(id: string, options?: AxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).feedbackControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {'DESC' | 'ASC'} [sort] 
     * @param {string} [userId] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackControllerGet(page: number, sort?: 'DESC' | 'ASC', userId?: string, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).feedbackControllerGet(page, sort, userId, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateFeedbackDto} updateFeedbackDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackControllerUpdate(id: string, updateFeedbackDto: UpdateFeedbackDto, options?: AxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).feedbackControllerUpdate(id, updateFeedbackDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LessonApi - axios parameter creator
 * @export
 */
export const LessonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lessonControllerDelete', 'id', id)
            const localVarPath = `/lesson/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {string} [courseId] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonControllerGet: async (page: number, sort?: 'DESC' | 'ASC', courseId?: string, size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('lessonControllerGet', 'page', page)
            const localVarPath = `/lesson/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonControllerGetById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lessonControllerGetById', 'id', id)
            const localVarPath = `/lesson/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LessonApi - functional programming interface
 * @export
 */
export const LessonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LessonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lessonControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lessonControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {string} [courseId] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lessonControllerGet(page: number, sort?: 'DESC' | 'ASC', courseId?: string, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LessonControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lessonControllerGet(page, sort, courseId, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lessonControllerGetById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lesson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lessonControllerGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LessonApi - factory interface
 * @export
 */
export const LessonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LessonApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.lessonControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {string} [courseId] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonControllerGet(page: number, sort?: 'DESC' | 'ASC', courseId?: string, size?: number, fullTextSearch?: string, options?: any): AxiosPromise<LessonControllerGet200Response> {
            return localVarFp.lessonControllerGet(page, sort, courseId, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonControllerGetById(id: string, options?: any): AxiosPromise<Lesson> {
            return localVarFp.lessonControllerGetById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LessonApi - object-oriented interface
 * @export
 * @class LessonApi
 * @extends {BaseAPI}
 */
export class LessonApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    public lessonControllerDelete(id: string, options?: AxiosRequestConfig) {
        return LessonApiFp(this.configuration).lessonControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {'DESC' | 'ASC'} [sort] 
     * @param {string} [courseId] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    public lessonControllerGet(page: number, sort?: 'DESC' | 'ASC', courseId?: string, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return LessonApiFp(this.configuration).lessonControllerGet(page, sort, courseId, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonApi
     */
    public lessonControllerGetById(id: string, options?: AxiosRequestConfig) {
        return LessonApiFp(this.configuration).lessonControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LessonLearnApi - axios parameter creator
 * @export
 */
export const LessonLearnApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateLessonLearn} createLessonLearn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonLearnControllerCreate: async (createLessonLearn: CreateLessonLearn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLessonLearn' is not null or undefined
            assertParamExists('lessonLearnControllerCreate', 'createLessonLearn', createLessonLearn)
            const localVarPath = `/lesson-learn/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLessonLearn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {string} [courseId] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonLearnControllerGetByUserId: async (userId: string, page: number, sort?: 'DESC' | 'ASC', courseId?: string, size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('lessonLearnControllerGetByUserId', 'userId', userId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('lessonLearnControllerGetByUserId', 'page', page)
            const localVarPath = `/lesson-learn/get/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLessonLearnDto} updateLessonLearnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonLearnControllerUpdate: async (id: string, updateLessonLearnDto: UpdateLessonLearnDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lessonLearnControllerUpdate', 'id', id)
            // verify required parameter 'updateLessonLearnDto' is not null or undefined
            assertParamExists('lessonLearnControllerUpdate', 'updateLessonLearnDto', updateLessonLearnDto)
            const localVarPath = `/lesson-learn/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLessonLearnDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LessonLearnApi - functional programming interface
 * @export
 */
export const LessonLearnApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LessonLearnApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateLessonLearn} createLessonLearn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lessonLearnControllerCreate(createLessonLearn: CreateLessonLearn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LessonLearn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lessonLearnControllerCreate(createLessonLearn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {string} [courseId] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lessonLearnControllerGetByUserId(userId: string, page: number, sort?: 'DESC' | 'ASC', courseId?: string, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LessonLearnControllerGetByUserId200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lessonLearnControllerGetByUserId(userId, page, sort, courseId, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLessonLearnDto} updateLessonLearnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lessonLearnControllerUpdate(id: string, updateLessonLearnDto: UpdateLessonLearnDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lessonLearnControllerUpdate(id, updateLessonLearnDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LessonLearnApi - factory interface
 * @export
 */
export const LessonLearnApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LessonLearnApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateLessonLearn} createLessonLearn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonLearnControllerCreate(createLessonLearn: CreateLessonLearn, options?: any): AxiosPromise<LessonLearn> {
            return localVarFp.lessonLearnControllerCreate(createLessonLearn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {string} [courseId] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonLearnControllerGetByUserId(userId: string, page: number, sort?: 'DESC' | 'ASC', courseId?: string, size?: number, fullTextSearch?: string, options?: any): AxiosPromise<LessonLearnControllerGetByUserId200Response> {
            return localVarFp.lessonLearnControllerGetByUserId(userId, page, sort, courseId, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLessonLearnDto} updateLessonLearnDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonLearnControllerUpdate(id: string, updateLessonLearnDto: UpdateLessonLearnDto, options?: any): AxiosPromise<object> {
            return localVarFp.lessonLearnControllerUpdate(id, updateLessonLearnDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LessonLearnApi - object-oriented interface
 * @export
 * @class LessonLearnApi
 * @extends {BaseAPI}
 */
export class LessonLearnApi extends BaseAPI {
    /**
     * 
     * @param {CreateLessonLearn} createLessonLearn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonLearnApi
     */
    public lessonLearnControllerCreate(createLessonLearn: CreateLessonLearn, options?: AxiosRequestConfig) {
        return LessonLearnApiFp(this.configuration).lessonLearnControllerCreate(createLessonLearn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {number} page 
     * @param {'DESC' | 'ASC'} [sort] 
     * @param {string} [courseId] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonLearnApi
     */
    public lessonLearnControllerGetByUserId(userId: string, page: number, sort?: 'DESC' | 'ASC', courseId?: string, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return LessonLearnApiFp(this.configuration).lessonLearnControllerGetByUserId(userId, page, sort, courseId, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateLessonLearnDto} updateLessonLearnDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonLearnApi
     */
    public lessonLearnControllerUpdate(id: string, updateLessonLearnDto: UpdateLessonLearnDto, options?: AxiosRequestConfig) {
        return LessonLearnApiFp(this.configuration).lessonLearnControllerUpdate(id, updateLessonLearnDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LessonLearnCheckApi - axios parameter creator
 * @export
 */
export const LessonLearnCheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateLessonLearnCheck} createLessonLearnCheck 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonLearnCheckControllerCreate: async (createLessonLearnCheck: CreateLessonLearnCheck, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLessonLearnCheck' is not null or undefined
            assertParamExists('lessonLearnCheckControllerCreate', 'createLessonLearnCheck', createLessonLearnCheck)
            const localVarPath = `/lesson-learn-check/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLessonLearnCheck, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LessonLearnCheckApi - functional programming interface
 * @export
 */
export const LessonLearnCheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LessonLearnCheckApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateLessonLearnCheck} createLessonLearnCheck 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lessonLearnCheckControllerCreate(createLessonLearnCheck: CreateLessonLearnCheck, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lessonLearnCheckControllerCreate(createLessonLearnCheck, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LessonLearnCheckApi - factory interface
 * @export
 */
export const LessonLearnCheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LessonLearnCheckApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateLessonLearnCheck} createLessonLearnCheck 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonLearnCheckControllerCreate(createLessonLearnCheck: CreateLessonLearnCheck, options?: any): AxiosPromise<object> {
            return localVarFp.lessonLearnCheckControllerCreate(createLessonLearnCheck, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LessonLearnCheckApi - object-oriented interface
 * @export
 * @class LessonLearnCheckApi
 * @extends {BaseAPI}
 */
export class LessonLearnCheckApi extends BaseAPI {
    /**
     * 
     * @param {CreateLessonLearnCheck} createLessonLearnCheck 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonLearnCheckApi
     */
    public lessonLearnCheckControllerCreate(createLessonLearnCheck: CreateLessonLearnCheck, options?: AxiosRequestConfig) {
        return LessonLearnCheckApiFp(this.configuration).lessonLearnCheckControllerCreate(createLessonLearnCheck, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LessonQuestionApi - axios parameter creator
 * @export
 */
export const LessonQuestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {QuestionDto} questionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonquestionControllerCreate: async (questionDto: QuestionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionDto' is not null or undefined
            assertParamExists('lessonquestionControllerCreate', 'questionDto', questionDto)
            const localVarPath = `/lesson-question/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonquestionControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lessonquestionControllerDelete', 'id', id)
            const localVarPath = `/lesson-question/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonquestionControllerGetByCourseId: async (courseId: string, page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('lessonquestionControllerGetByCourseId', 'courseId', courseId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('lessonquestionControllerGetByCourseId', 'page', page)
            const localVarPath = `/lesson-question/get/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonquestionControllerGetByLessonId: async (lessonId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('lessonquestionControllerGetByLessonId', 'lessonId', lessonId)
            const localVarPath = `/lesson-question/lessonId/{lessonId}`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LessonQuestionApi - functional programming interface
 * @export
 */
export const LessonQuestionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LessonQuestionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {QuestionDto} questionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lessonquestionControllerCreate(questionDto: QuestionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lessonquestionControllerCreate(questionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lessonquestionControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lessonquestionControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} courseId 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lessonquestionControllerGetByCourseId(courseId: string, page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LessonquestionControllerGetByCourseId200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lessonquestionControllerGetByCourseId(courseId, page, sort, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lessonquestionControllerGetByLessonId(lessonId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lessonquestionControllerGetByLessonId(lessonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LessonQuestionApi - factory interface
 * @export
 */
export const LessonQuestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LessonQuestionApiFp(configuration)
    return {
        /**
         * 
         * @param {QuestionDto} questionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonquestionControllerCreate(questionDto: QuestionDto, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.lessonquestionControllerCreate(questionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonquestionControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.lessonquestionControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonquestionControllerGetByCourseId(courseId: string, page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options?: any): AxiosPromise<LessonquestionControllerGetByCourseId200Response> {
            return localVarFp.lessonquestionControllerGetByCourseId(courseId, page, sort, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonquestionControllerGetByLessonId(lessonId: string, options?: any): AxiosPromise<object> {
            return localVarFp.lessonquestionControllerGetByLessonId(lessonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LessonQuestionApi - object-oriented interface
 * @export
 * @class LessonQuestionApi
 * @extends {BaseAPI}
 */
export class LessonQuestionApi extends BaseAPI {
    /**
     * 
     * @param {QuestionDto} questionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonQuestionApi
     */
    public lessonquestionControllerCreate(questionDto: QuestionDto, options?: AxiosRequestConfig) {
        return LessonQuestionApiFp(this.configuration).lessonquestionControllerCreate(questionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonQuestionApi
     */
    public lessonquestionControllerDelete(id: string, options?: AxiosRequestConfig) {
        return LessonQuestionApiFp(this.configuration).lessonquestionControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId 
     * @param {number} page 
     * @param {'DESC' | 'ASC'} [sort] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonQuestionApi
     */
    public lessonquestionControllerGetByCourseId(courseId: string, page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return LessonQuestionApiFp(this.configuration).lessonquestionControllerGetByCourseId(courseId, page, sort, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} lessonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonQuestionApi
     */
    public lessonquestionControllerGetByLessonId(lessonId: string, options?: AxiosRequestConfig) {
        return LessonQuestionApiFp(this.configuration).lessonquestionControllerGetByLessonId(lessonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LessonQuestionAddOnTimeApi - axios parameter creator
 * @export
 */
export const LessonQuestionAddOnTimeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {string} [lessonId] 
         * @param {string} [courseId] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonquestionAddOnTimeControllerGetByUserId: async (userId: string, page: number, sort?: 'DESC' | 'ASC', lessonId?: string, courseId?: string, size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('lessonquestionAddOnTimeControllerGetByUserId', 'userId', userId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('lessonquestionAddOnTimeControllerGetByUserId', 'page', page)
            const localVarPath = `/lesson-question-add-on-time/get/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (lessonId !== undefined) {
                localVarQueryParameter['lessonId'] = lessonId;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LessonQuestionAddOnTimeApi - functional programming interface
 * @export
 */
export const LessonQuestionAddOnTimeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LessonQuestionAddOnTimeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {string} [lessonId] 
         * @param {string} [courseId] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lessonquestionAddOnTimeControllerGetByUserId(userId: string, page: number, sort?: 'DESC' | 'ASC', lessonId?: string, courseId?: string, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LessonquestionAddOnTimeControllerGetByUserId200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lessonquestionAddOnTimeControllerGetByUserId(userId, page, sort, lessonId, courseId, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LessonQuestionAddOnTimeApi - factory interface
 * @export
 */
export const LessonQuestionAddOnTimeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LessonQuestionAddOnTimeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {string} [lessonId] 
         * @param {string} [courseId] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonquestionAddOnTimeControllerGetByUserId(userId: string, page: number, sort?: 'DESC' | 'ASC', lessonId?: string, courseId?: string, size?: number, fullTextSearch?: string, options?: any): AxiosPromise<LessonquestionAddOnTimeControllerGetByUserId200Response> {
            return localVarFp.lessonquestionAddOnTimeControllerGetByUserId(userId, page, sort, lessonId, courseId, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LessonQuestionAddOnTimeApi - object-oriented interface
 * @export
 * @class LessonQuestionAddOnTimeApi
 * @extends {BaseAPI}
 */
export class LessonQuestionAddOnTimeApi extends BaseAPI {
    /**
     * 
     * @param {string} userId 
     * @param {number} page 
     * @param {'DESC' | 'ASC'} [sort] 
     * @param {string} [lessonId] 
     * @param {string} [courseId] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonQuestionAddOnTimeApi
     */
    public lessonquestionAddOnTimeControllerGetByUserId(userId: string, page: number, sort?: 'DESC' | 'ASC', lessonId?: string, courseId?: string, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return LessonQuestionAddOnTimeApiFp(this.configuration).lessonquestionAddOnTimeControllerGetByUserId(userId, page, sort, lessonId, courseId, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LessonQuestionUserAnswerApi - axios parameter creator
 * @export
 */
export const LessonQuestionUserAnswerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ArrayAnswer} arrayAnswer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonquestionUserAnswerControllerSubmit: async (arrayAnswer: ArrayAnswer, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'arrayAnswer' is not null or undefined
            assertParamExists('lessonquestionUserAnswerControllerSubmit', 'arrayAnswer', arrayAnswer)
            const localVarPath = `/lesson-question-user-answer/submit/answer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(arrayAnswer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LessonQuestionUserAnswerApi - functional programming interface
 * @export
 */
export const LessonQuestionUserAnswerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LessonQuestionUserAnswerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ArrayAnswer} arrayAnswer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lessonquestionUserAnswerControllerSubmit(arrayAnswer: ArrayAnswer, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lessonquestionUserAnswerControllerSubmit(arrayAnswer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LessonQuestionUserAnswerApi - factory interface
 * @export
 */
export const LessonQuestionUserAnswerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LessonQuestionUserAnswerApiFp(configuration)
    return {
        /**
         * 
         * @param {ArrayAnswer} arrayAnswer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lessonquestionUserAnswerControllerSubmit(arrayAnswer: ArrayAnswer, options?: any): AxiosPromise<object> {
            return localVarFp.lessonquestionUserAnswerControllerSubmit(arrayAnswer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LessonQuestionUserAnswerApi - object-oriented interface
 * @export
 * @class LessonQuestionUserAnswerApi
 * @extends {BaseAPI}
 */
export class LessonQuestionUserAnswerApi extends BaseAPI {
    /**
     * 
     * @param {ArrayAnswer} arrayAnswer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LessonQuestionUserAnswerApi
     */
    public lessonquestionUserAnswerControllerSubmit(arrayAnswer: ArrayAnswer, options?: AxiosRequestConfig) {
        return LessonQuestionUserAnswerApiFp(this.configuration).lessonquestionUserAnswerControllerSubmit(arrayAnswer, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MailsApi - axios parameter creator
 * @export
 */
export const MailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailControllerTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mails/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailsApi - functional programming interface
 * @export
 */
export const MailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailControllerTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mailControllerTest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailsApi - factory interface
 * @export
 */
export const MailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailControllerTest(options?: any): AxiosPromise<void> {
            return localVarFp.mailControllerTest(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailsApi - object-oriented interface
 * @export
 * @class MailsApi
 * @extends {BaseAPI}
 */
export class MailsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailsApi
     */
    public mailControllerTest(options?: AxiosRequestConfig) {
        return MailsApiFp(this.configuration).mailControllerTest(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsApi - axios parameter creator
 * @export
 */
export const NewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateNewsDto} createNewsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerCreateNews: async (createNewsDto: CreateNewsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNewsDto' is not null or undefined
            assertParamExists('newsControllerCreateNews', 'createNewsDto', createNewsDto)
            const localVarPath = `/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNewsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerDeleteNews: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsControllerDeleteNews', 'id', id)
            const localVarPath = `/news/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {boolean} [isVisible] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerGet: async (page: number, sort?: 'DESC' | 'ASC', isVisible?: boolean, from?: string, to?: string, size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('newsControllerGet', 'page', page)
            const localVarPath = `/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (isVisible !== undefined) {
                localVarQueryParameter['isVisible'] = isVisible;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerGetDetail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsControllerGetDetail', 'id', id)
            const localVarPath = `/news/get-by-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateNewsDto} updateNewsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerUpdateNews: async (id: string, updateNewsDto: UpdateNewsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsControllerUpdateNews', 'id', id)
            // verify required parameter 'updateNewsDto' is not null or undefined
            assertParamExists('newsControllerUpdateNews', 'updateNewsDto', updateNewsDto)
            const localVarPath = `/news/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNewsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsApi - functional programming interface
 * @export
 */
export const NewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateNewsDto} createNewsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsControllerCreateNews(createNewsDto: CreateNewsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsControllerCreateNews(createNewsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsControllerDeleteNews(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsControllerDeleteNews(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {boolean} [isVisible] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsControllerGet(page: number, sort?: 'DESC' | 'ASC', isVisible?: boolean, from?: string, to?: string, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsControllerGet(page, sort, isVisible, from, to, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsControllerGetDetail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<News>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsControllerGetDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateNewsDto} updateNewsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsControllerUpdateNews(id: string, updateNewsDto: UpdateNewsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<News>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsControllerUpdateNews(id, updateNewsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsApi - factory interface
 * @export
 */
export const NewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateNewsDto} createNewsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerCreateNews(createNewsDto: CreateNewsDto, options?: any): AxiosPromise<object> {
            return localVarFp.newsControllerCreateNews(createNewsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerDeleteNews(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.newsControllerDeleteNews(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {boolean} [isVisible] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerGet(page: number, sort?: 'DESC' | 'ASC', isVisible?: boolean, from?: string, to?: string, size?: number, fullTextSearch?: string, options?: any): AxiosPromise<NewsControllerGet200Response> {
            return localVarFp.newsControllerGet(page, sort, isVisible, from, to, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerGetDetail(id: string, options?: any): AxiosPromise<News> {
            return localVarFp.newsControllerGetDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateNewsDto} updateNewsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerUpdateNews(id: string, updateNewsDto: UpdateNewsDto, options?: any): AxiosPromise<News> {
            return localVarFp.newsControllerUpdateNews(id, updateNewsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsApi - object-oriented interface
 * @export
 * @class NewsApi
 * @extends {BaseAPI}
 */
export class NewsApi extends BaseAPI {
    /**
     * 
     * @param {CreateNewsDto} createNewsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsControllerCreateNews(createNewsDto: CreateNewsDto, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsControllerCreateNews(createNewsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsControllerDeleteNews(id: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsControllerDeleteNews(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {'DESC' | 'ASC'} [sort] 
     * @param {boolean} [isVisible] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsControllerGet(page: number, sort?: 'DESC' | 'ASC', isVisible?: boolean, from?: string, to?: string, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsControllerGet(page, sort, isVisible, from, to, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsControllerGetDetail(id: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsControllerGetDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateNewsDto} updateNewsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsControllerUpdateNews(id: string, updateNewsDto: UpdateNewsDto, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsControllerUpdateNews(id, updateNewsDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotifiApi - axios parameter creator
 * @export
 */
export const NotifiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerGet: async (page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('notificationControllerGet', 'page', page)
            const localVarPath = `/notifi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerGetCountUnred: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifi/count-unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerRead: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationControllerRead', 'id', id)
            const localVarPath = `/notifi/read/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerReadAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifi/read-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotifiApi - functional programming interface
 * @export
 */
export const NotifiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotifiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerGet(page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerGet(page, sort, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerGetCountUnred(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerGetCountUnred(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerRead(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerReadAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerReadAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotifiApi - factory interface
 * @export
 */
export const NotifiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotifiApiFp(configuration)
    return {
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerGet(page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options?: any): AxiosPromise<NotificationControllerGet200Response> {
            return localVarFp.notificationControllerGet(page, sort, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerGetCountUnred(options?: any): AxiosPromise<object> {
            return localVarFp.notificationControllerGetCountUnred(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerRead(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.notificationControllerRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerReadAll(options?: any): AxiosPromise<void> {
            return localVarFp.notificationControllerReadAll(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotifiApi - object-oriented interface
 * @export
 * @class NotifiApi
 * @extends {BaseAPI}
 */
export class NotifiApi extends BaseAPI {
    /**
     * 
     * @param {number} page 
     * @param {'DESC' | 'ASC'} [sort] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotifiApi
     */
    public notificationControllerGet(page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return NotifiApiFp(this.configuration).notificationControllerGet(page, sort, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotifiApi
     */
    public notificationControllerGetCountUnred(options?: AxiosRequestConfig) {
        return NotifiApiFp(this.configuration).notificationControllerGetCountUnred(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotifiApi
     */
    public notificationControllerRead(id: string, options?: AxiosRequestConfig) {
        return NotifiApiFp(this.configuration).notificationControllerRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotifiApi
     */
    public notificationControllerReadAll(options?: AxiosRequestConfig) {
        return NotifiApiFp(this.configuration).notificationControllerReadAll(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OtpApi - axios parameter creator
 * @export
 */
export const OtpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FilterOtpDto} filterOtpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        otpControllerSend: async (filterOtpDto: FilterOtpDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterOtpDto' is not null or undefined
            assertParamExists('otpControllerSend', 'filterOtpDto', filterOtpDto)
            const localVarPath = `/otp/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterOtpDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyOtpDto} verifyOtpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        otpControllerVerifyOtp: async (verifyOtpDto: VerifyOtpDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyOtpDto' is not null or undefined
            assertParamExists('otpControllerVerifyOtp', 'verifyOtpDto', verifyOtpDto)
            const localVarPath = `/otp/verifyOtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyOtpDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OtpApi - functional programming interface
 * @export
 */
export const OtpApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OtpApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FilterOtpDto} filterOtpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async otpControllerSend(filterOtpDto: FilterOtpDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.otpControllerSend(filterOtpDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyOtpDto} verifyOtpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async otpControllerVerifyOtp(verifyOtpDto: VerifyOtpDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Otp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.otpControllerVerifyOtp(verifyOtpDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OtpApi - factory interface
 * @export
 */
export const OtpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OtpApiFp(configuration)
    return {
        /**
         * 
         * @param {FilterOtpDto} filterOtpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        otpControllerSend(filterOtpDto: FilterOtpDto, options?: any): AxiosPromise<void> {
            return localVarFp.otpControllerSend(filterOtpDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyOtpDto} verifyOtpDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        otpControllerVerifyOtp(verifyOtpDto: VerifyOtpDto, options?: any): AxiosPromise<Otp> {
            return localVarFp.otpControllerVerifyOtp(verifyOtpDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OtpApi - object-oriented interface
 * @export
 * @class OtpApi
 * @extends {BaseAPI}
 */
export class OtpApi extends BaseAPI {
    /**
     * 
     * @param {FilterOtpDto} filterOtpDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtpApi
     */
    public otpControllerSend(filterOtpDto: FilterOtpDto, options?: AxiosRequestConfig) {
        return OtpApiFp(this.configuration).otpControllerSend(filterOtpDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyOtpDto} verifyOtpDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtpApi
     */
    public otpControllerVerifyOtp(verifyOtpDto: VerifyOtpDto, options?: AxiosRequestConfig) {
        return OtpApiFp(this.configuration).otpControllerVerifyOtp(verifyOtpDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PermissionsApi - axios parameter creator
 * @export
 */
export const PermissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionControllerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PermissionsApi - functional programming interface
 * @export
 */
export const PermissionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PermissionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionControllerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PermissionGroupDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionControllerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PermissionsApi - factory interface
 * @export
 */
export const PermissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PermissionsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionControllerGet(options?: any): AxiosPromise<Array<PermissionGroupDto>> {
            return localVarFp.permissionControllerGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PermissionsApi - object-oriented interface
 * @export
 * @class PermissionsApi
 * @extends {BaseAPI}
 */
export class PermissionsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public permissionControllerGet(options?: AxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).permissionControllerGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RecruitApi - axios parameter creator
 * @export
 */
export const RecruitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRecruitDto} createRecruitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitControllerCreateRecruit: async (createRecruitDto: CreateRecruitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRecruitDto' is not null or undefined
            assertParamExists('recruitControllerCreateRecruit', 'createRecruitDto', createRecruitDto)
            const localVarPath = `/recruit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRecruitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitControllerDeleteRecruit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('recruitControllerDeleteRecruit', 'id', id)
            const localVarPath = `/recruit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {boolean} [isVisible] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitControllerGet: async (page: number, sort?: 'DESC' | 'ASC', isVisible?: boolean, from?: string, to?: string, size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('recruitControllerGet', 'page', page)
            const localVarPath = `/recruit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (isVisible !== undefined) {
                localVarQueryParameter['isVisible'] = isVisible;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitControllerGetDetail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('recruitControllerGetDetail', 'id', id)
            const localVarPath = `/recruit/get-by-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRecruitDto} updateRecruitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitControllerUpdateRecruit: async (id: string, updateRecruitDto: UpdateRecruitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('recruitControllerUpdateRecruit', 'id', id)
            // verify required parameter 'updateRecruitDto' is not null or undefined
            assertParamExists('recruitControllerUpdateRecruit', 'updateRecruitDto', updateRecruitDto)
            const localVarPath = `/recruit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRecruitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecruitApi - functional programming interface
 * @export
 */
export const RecruitApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecruitApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRecruitDto} createRecruitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recruitControllerCreateRecruit(createRecruitDto: CreateRecruitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recruitControllerCreateRecruit(createRecruitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recruitControllerDeleteRecruit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recruitControllerDeleteRecruit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {boolean} [isVisible] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recruitControllerGet(page: number, sort?: 'DESC' | 'ASC', isVisible?: boolean, from?: string, to?: string, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recruitControllerGet(page, sort, isVisible, from, to, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recruitControllerGetDetail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<News>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recruitControllerGetDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRecruitDto} updateRecruitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recruitControllerUpdateRecruit(id: string, updateRecruitDto: UpdateRecruitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<News>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recruitControllerUpdateRecruit(id, updateRecruitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecruitApi - factory interface
 * @export
 */
export const RecruitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecruitApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateRecruitDto} createRecruitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitControllerCreateRecruit(createRecruitDto: CreateRecruitDto, options?: any): AxiosPromise<object> {
            return localVarFp.recruitControllerCreateRecruit(createRecruitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitControllerDeleteRecruit(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.recruitControllerDeleteRecruit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {boolean} [isVisible] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitControllerGet(page: number, sort?: 'DESC' | 'ASC', isVisible?: boolean, from?: string, to?: string, size?: number, fullTextSearch?: string, options?: any): AxiosPromise<NewsControllerGet200Response> {
            return localVarFp.recruitControllerGet(page, sort, isVisible, from, to, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitControllerGetDetail(id: string, options?: any): AxiosPromise<News> {
            return localVarFp.recruitControllerGetDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRecruitDto} updateRecruitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recruitControllerUpdateRecruit(id: string, updateRecruitDto: UpdateRecruitDto, options?: any): AxiosPromise<News> {
            return localVarFp.recruitControllerUpdateRecruit(id, updateRecruitDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecruitApi - object-oriented interface
 * @export
 * @class RecruitApi
 * @extends {BaseAPI}
 */
export class RecruitApi extends BaseAPI {
    /**
     * 
     * @param {CreateRecruitDto} createRecruitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecruitApi
     */
    public recruitControllerCreateRecruit(createRecruitDto: CreateRecruitDto, options?: AxiosRequestConfig) {
        return RecruitApiFp(this.configuration).recruitControllerCreateRecruit(createRecruitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecruitApi
     */
    public recruitControllerDeleteRecruit(id: string, options?: AxiosRequestConfig) {
        return RecruitApiFp(this.configuration).recruitControllerDeleteRecruit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {'DESC' | 'ASC'} [sort] 
     * @param {boolean} [isVisible] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecruitApi
     */
    public recruitControllerGet(page: number, sort?: 'DESC' | 'ASC', isVisible?: boolean, from?: string, to?: string, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return RecruitApiFp(this.configuration).recruitControllerGet(page, sort, isVisible, from, to, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecruitApi
     */
    public recruitControllerGetDetail(id: string, options?: AxiosRequestConfig) {
        return RecruitApiFp(this.configuration).recruitControllerGetDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateRecruitDto} updateRecruitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecruitApi
     */
    public recruitControllerUpdateRecruit(id: string, updateRecruitDto: UpdateRecruitDto, options?: AxiosRequestConfig) {
        return RecruitApiFp(this.configuration).recruitControllerUpdateRecruit(id, updateRecruitDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRoleDto} createRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerCreate: async (createRoleDto: CreateRoleDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRoleDto' is not null or undefined
            assertParamExists('roleControllerCreate', 'createRoleDto', createRoleDto)
            const localVarPath = `/roles/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('roleControllerDelete', 'id', id)
            const localVarPath = `/roles/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGet: async (page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('roleControllerGet', 'page', page)
            const localVarPath = `/roles/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGetById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('roleControllerGetById', 'id', id)
            const localVarPath = `/roles/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRoleDto} updateRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerUpdate: async (id: string, updateRoleDto: UpdateRoleDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('roleControllerUpdate', 'id', id)
            // verify required parameter 'updateRoleDto' is not null or undefined
            assertParamExists('roleControllerUpdate', 'updateRoleDto', updateRoleDto)
            const localVarPath = `/roles/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRoleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRoleDto} createRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerCreate(createRoleDto: CreateRoleDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerCreate(createRoleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerGet(page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerGet(page, sort, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerGetById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRoleDto} updateRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerUpdate(id: string, updateRoleDto: UpdateRoleDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerUpdate(id, updateRoleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateRoleDto} createRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerCreate(createRoleDto: CreateRoleDto, options?: any): AxiosPromise<Role> {
            return localVarFp.roleControllerCreate(createRoleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.roleControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGet(page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options?: any): AxiosPromise<RoleControllerGet200Response> {
            return localVarFp.roleControllerGet(page, sort, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGetById(id: string, options?: any): AxiosPromise<Role> {
            return localVarFp.roleControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRoleDto} updateRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerUpdate(id: string, updateRoleDto: UpdateRoleDto, options?: any): AxiosPromise<Role> {
            return localVarFp.roleControllerUpdate(id, updateRoleDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @param {CreateRoleDto} createRoleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerCreate(createRoleDto: CreateRoleDto, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerCreate(createRoleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerDelete(id: string, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {'DESC' | 'ASC'} [sort] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerGet(page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerGet(page, sort, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerGetById(id: string, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateRoleDto} updateRoleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerUpdate(id: string, updateRoleDto: UpdateRoleDto, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerUpdate(id, updateRoleDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatisticalApi - axios parameter creator
 * @export
 */
export const StatisticalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticalControllerRate: async (from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistical/rate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'administrator' | 'trainer' | 'customer'} [typeSkip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticalControllerRatio: async (typeSkip?: 'administrator' | 'trainer' | 'customer', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistical/ratio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (typeSkip !== undefined) {
                localVarQueryParameter['typeSkip'] = typeSkip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticalControllerStudent: async (from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistical/student`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticalControllerStudentTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistical/student-test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticalControllerTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistical/lesson-test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {boolean} [ranking] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticalControllerTrainer: async (from?: string, to?: string, ranking?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistical/trainer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (ranking !== undefined) {
                localVarQueryParameter['ranking'] = ranking;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticalApi - functional programming interface
 * @export
 */
export const StatisticalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticalControllerRate(from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticalControllerRate(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'administrator' | 'trainer' | 'customer'} [typeSkip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticalControllerRatio(typeSkip?: 'administrator' | 'trainer' | 'customer', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticalControllerRatio(typeSkip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticalControllerStudent(from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticalControllerStudent(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticalControllerStudentTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticalControllerStudentTest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticalControllerTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticalControllerTest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {boolean} [ranking] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticalControllerTrainer(from?: string, to?: string, ranking?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticalControllerTrainer(from, to, ranking, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatisticalApi - factory interface
 * @export
 */
export const StatisticalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticalApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticalControllerRate(from?: string, to?: string, options?: any): AxiosPromise<void> {
            return localVarFp.statisticalControllerRate(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'administrator' | 'trainer' | 'customer'} [typeSkip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticalControllerRatio(typeSkip?: 'administrator' | 'trainer' | 'customer', options?: any): AxiosPromise<void> {
            return localVarFp.statisticalControllerRatio(typeSkip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticalControllerStudent(from?: string, to?: string, options?: any): AxiosPromise<void> {
            return localVarFp.statisticalControllerStudent(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticalControllerStudentTest(options?: any): AxiosPromise<void> {
            return localVarFp.statisticalControllerStudentTest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticalControllerTest(options?: any): AxiosPromise<void> {
            return localVarFp.statisticalControllerTest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {boolean} [ranking] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticalControllerTrainer(from?: string, to?: string, ranking?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.statisticalControllerTrainer(from, to, ranking, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatisticalApi - object-oriented interface
 * @export
 * @class StatisticalApi
 * @extends {BaseAPI}
 */
export class StatisticalApi extends BaseAPI {
    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticalApi
     */
    public statisticalControllerRate(from?: string, to?: string, options?: AxiosRequestConfig) {
        return StatisticalApiFp(this.configuration).statisticalControllerRate(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'administrator' | 'trainer' | 'customer'} [typeSkip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticalApi
     */
    public statisticalControllerRatio(typeSkip?: 'administrator' | 'trainer' | 'customer', options?: AxiosRequestConfig) {
        return StatisticalApiFp(this.configuration).statisticalControllerRatio(typeSkip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticalApi
     */
    public statisticalControllerStudent(from?: string, to?: string, options?: AxiosRequestConfig) {
        return StatisticalApiFp(this.configuration).statisticalControllerStudent(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticalApi
     */
    public statisticalControllerStudentTest(options?: AxiosRequestConfig) {
        return StatisticalApiFp(this.configuration).statisticalControllerStudentTest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticalApi
     */
    public statisticalControllerTest(options?: AxiosRequestConfig) {
        return StatisticalApiFp(this.configuration).statisticalControllerTest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {boolean} [ranking] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticalApi
     */
    public statisticalControllerTrainer(from?: string, to?: string, ranking?: boolean, options?: AxiosRequestConfig) {
        return StatisticalApiFp(this.configuration).statisticalControllerTrainer(from, to, ranking, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SurveyQuestionApi - axios parameter creator
 * @export
 */
export const SurveyQuestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateSurveyQuestionDto} createSurveyQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyQuestionControllerCreate: async (createSurveyQuestionDto: CreateSurveyQuestionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSurveyQuestionDto' is not null or undefined
            assertParamExists('surveyQuestionControllerCreate', 'createSurveyQuestionDto', createSurveyQuestionDto)
            const localVarPath = `/survey-question`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSurveyQuestionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyQuestionControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('surveyQuestionControllerDelete', 'id', id)
            const localVarPath = `/survey-question/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyQuestionControllerGet: async (page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('surveyQuestionControllerGet', 'page', page)
            const localVarPath = `/survey-question`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyQuestionControllerGetDetail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('surveyQuestionControllerGetDetail', 'id', id)
            const localVarPath = `/survey-question/get-by-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSurveyQuestionDto} updateSurveyQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyQuestionControllerUpdate: async (id: string, updateSurveyQuestionDto: UpdateSurveyQuestionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('surveyQuestionControllerUpdate', 'id', id)
            // verify required parameter 'updateSurveyQuestionDto' is not null or undefined
            assertParamExists('surveyQuestionControllerUpdate', 'updateSurveyQuestionDto', updateSurveyQuestionDto)
            const localVarPath = `/survey-question/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSurveyQuestionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveyQuestionApi - functional programming interface
 * @export
 */
export const SurveyQuestionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SurveyQuestionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateSurveyQuestionDto} createSurveyQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyQuestionControllerCreate(createSurveyQuestionDto: CreateSurveyQuestionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyQuestionControllerCreate(createSurveyQuestionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyQuestionControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyQuestionControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyQuestionControllerGet(page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyQuestionControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyQuestionControllerGet(page, sort, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyQuestionControllerGetDetail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyQuestion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyQuestionControllerGetDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSurveyQuestionDto} updateSurveyQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyQuestionControllerUpdate(id: string, updateSurveyQuestionDto: UpdateSurveyQuestionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.surveyQuestionControllerUpdate(id, updateSurveyQuestionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SurveyQuestionApi - factory interface
 * @export
 */
export const SurveyQuestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SurveyQuestionApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateSurveyQuestionDto} createSurveyQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyQuestionControllerCreate(createSurveyQuestionDto: CreateSurveyQuestionDto, options?: any): AxiosPromise<object> {
            return localVarFp.surveyQuestionControllerCreate(createSurveyQuestionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyQuestionControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.surveyQuestionControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyQuestionControllerGet(page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options?: any): AxiosPromise<SurveyQuestionControllerGet200Response> {
            return localVarFp.surveyQuestionControllerGet(page, sort, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyQuestionControllerGetDetail(id: string, options?: any): AxiosPromise<SurveyQuestion> {
            return localVarFp.surveyQuestionControllerGetDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSurveyQuestionDto} updateSurveyQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyQuestionControllerUpdate(id: string, updateSurveyQuestionDto: UpdateSurveyQuestionDto, options?: any): AxiosPromise<object> {
            return localVarFp.surveyQuestionControllerUpdate(id, updateSurveyQuestionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SurveyQuestionApi - object-oriented interface
 * @export
 * @class SurveyQuestionApi
 * @extends {BaseAPI}
 */
export class SurveyQuestionApi extends BaseAPI {
    /**
     * 
     * @param {CreateSurveyQuestionDto} createSurveyQuestionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyQuestionApi
     */
    public surveyQuestionControllerCreate(createSurveyQuestionDto: CreateSurveyQuestionDto, options?: AxiosRequestConfig) {
        return SurveyQuestionApiFp(this.configuration).surveyQuestionControllerCreate(createSurveyQuestionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyQuestionApi
     */
    public surveyQuestionControllerDelete(id: string, options?: AxiosRequestConfig) {
        return SurveyQuestionApiFp(this.configuration).surveyQuestionControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {'DESC' | 'ASC'} [sort] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyQuestionApi
     */
    public surveyQuestionControllerGet(page: number, sort?: 'DESC' | 'ASC', size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return SurveyQuestionApiFp(this.configuration).surveyQuestionControllerGet(page, sort, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyQuestionApi
     */
    public surveyQuestionControllerGetDetail(id: string, options?: AxiosRequestConfig) {
        return SurveyQuestionApiFp(this.configuration).surveyQuestionControllerGetDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateSurveyQuestionDto} updateSurveyQuestionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyQuestionApi
     */
    public surveyQuestionControllerUpdate(id: string, updateSurveyQuestionDto: UpdateSurveyQuestionDto, options?: AxiosRequestConfig) {
        return SurveyQuestionApiFp(this.configuration).surveyQuestionControllerUpdate(id, updateSurveyQuestionDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrainerApi - axios parameter creator
 * @export
 */
export const TrainerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateTrainerDto} createTrainerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainerControllerCreate: async (createTrainerDto: CreateTrainerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTrainerDto' is not null or undefined
            assertParamExists('trainerControllerCreate', 'createTrainerDto', createTrainerDto)
            const localVarPath = `/trainer/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTrainerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainerControllerDelete: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('trainerControllerDelete', 'userId', userId)
            const localVarPath = `/trainer/delete/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [isActive] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainerControllerGet: async (page: number, sort?: 'DESC' | 'ASC', isActive?: number, size?: number, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('trainerControllerGet', 'page', page)
            const localVarPath = `/trainer/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainerControllerGetById: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('trainerControllerGetById', 'userId', userId)
            const localVarPath = `/trainer/get/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateTrainerDto} updateTrainerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainerControllerUpdate: async (userId: string, updateTrainerDto: UpdateTrainerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('trainerControllerUpdate', 'userId', userId)
            // verify required parameter 'updateTrainerDto' is not null or undefined
            assertParamExists('trainerControllerUpdate', 'updateTrainerDto', updateTrainerDto)
            const localVarPath = `/trainer/update/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTrainerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateTrainerDto} updateTrainerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainerControllerUpdateMe: async (updateTrainerDto: UpdateTrainerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTrainerDto' is not null or undefined
            assertParamExists('trainerControllerUpdateMe', 'updateTrainerDto', updateTrainerDto)
            const localVarPath = `/trainer/edit/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTrainerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrainerApi - functional programming interface
 * @export
 */
export const TrainerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrainerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateTrainerDto} createTrainerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainerControllerCreate(createTrainerDto: CreateTrainerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trainerControllerCreate(createTrainerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainerControllerDelete(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trainerControllerDelete(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [isActive] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainerControllerGet(page: number, sort?: 'DESC' | 'ASC', isActive?: number, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainerControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trainerControllerGet(page, sort, isActive, size, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainerControllerGetById(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trainerControllerGetById(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateTrainerDto} updateTrainerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainerControllerUpdate(userId: string, updateTrainerDto: UpdateTrainerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trainerControllerUpdate(userId, updateTrainerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateTrainerDto} updateTrainerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trainerControllerUpdateMe(updateTrainerDto: UpdateTrainerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trainerControllerUpdateMe(updateTrainerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrainerApi - factory interface
 * @export
 */
export const TrainerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrainerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateTrainerDto} createTrainerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainerControllerCreate(createTrainerDto: CreateTrainerDto, options?: any): AxiosPromise<object> {
            return localVarFp.trainerControllerCreate(createTrainerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainerControllerDelete(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.trainerControllerDelete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {'DESC' | 'ASC'} [sort] 
         * @param {number} [isActive] 
         * @param {number} [size] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainerControllerGet(page: number, sort?: 'DESC' | 'ASC', isActive?: number, size?: number, fullTextSearch?: string, options?: any): AxiosPromise<TrainerControllerGet200Response> {
            return localVarFp.trainerControllerGet(page, sort, isActive, size, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainerControllerGetById(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.trainerControllerGetById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateTrainerDto} updateTrainerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainerControllerUpdate(userId: string, updateTrainerDto: UpdateTrainerDto, options?: any): AxiosPromise<Trainer> {
            return localVarFp.trainerControllerUpdate(userId, updateTrainerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateTrainerDto} updateTrainerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trainerControllerUpdateMe(updateTrainerDto: UpdateTrainerDto, options?: any): AxiosPromise<Trainer> {
            return localVarFp.trainerControllerUpdateMe(updateTrainerDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrainerApi - object-oriented interface
 * @export
 * @class TrainerApi
 * @extends {BaseAPI}
 */
export class TrainerApi extends BaseAPI {
    /**
     * 
     * @param {CreateTrainerDto} createTrainerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainerApi
     */
    public trainerControllerCreate(createTrainerDto: CreateTrainerDto, options?: AxiosRequestConfig) {
        return TrainerApiFp(this.configuration).trainerControllerCreate(createTrainerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainerApi
     */
    public trainerControllerDelete(userId: string, options?: AxiosRequestConfig) {
        return TrainerApiFp(this.configuration).trainerControllerDelete(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {'DESC' | 'ASC'} [sort] 
     * @param {number} [isActive] 
     * @param {number} [size] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainerApi
     */
    public trainerControllerGet(page: number, sort?: 'DESC' | 'ASC', isActive?: number, size?: number, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return TrainerApiFp(this.configuration).trainerControllerGet(page, sort, isActive, size, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainerApi
     */
    public trainerControllerGetById(userId: string, options?: AxiosRequestConfig) {
        return TrainerApiFp(this.configuration).trainerControllerGetById(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {UpdateTrainerDto} updateTrainerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainerApi
     */
    public trainerControllerUpdate(userId: string, updateTrainerDto: UpdateTrainerDto, options?: AxiosRequestConfig) {
        return TrainerApiFp(this.configuration).trainerControllerUpdate(userId, updateTrainerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateTrainerDto} updateTrainerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainerApi
     */
    public trainerControllerUpdateMe(updateTrainerDto: UpdateTrainerDto, options?: AxiosRequestConfig) {
        return TrainerApiFp(this.configuration).trainerControllerUpdateMe(updateTrainerDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserSurveyQuestionApi - axios parameter creator
 * @export
 */
export const UserSurveyQuestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UserCreateSurveyAnswerDto} userCreateSurveyAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSurveyAnswerControllerCreate: async (userCreateSurveyAnswerDto: UserCreateSurveyAnswerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateSurveyAnswerDto' is not null or undefined
            assertParamExists('userSurveyAnswerControllerCreate', 'userCreateSurveyAnswerDto', userCreateSurveyAnswerDto)
            const localVarPath = `/user-survey-question`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateSurveyAnswerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSurveyAnswerControllerGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userSurveyAnswerControllerGet', 'id', id)
            const localVarPath = `/user-survey-question/get-by-user-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserSurveyQuestionApi - functional programming interface
 * @export
 */
export const UserSurveyQuestionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserSurveyQuestionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UserCreateSurveyAnswerDto} userCreateSurveyAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSurveyAnswerControllerCreate(userCreateSurveyAnswerDto: UserCreateSurveyAnswerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSurveyAnswerControllerCreate(userCreateSurveyAnswerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSurveyAnswerControllerGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserSurveyAnswer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSurveyAnswerControllerGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserSurveyQuestionApi - factory interface
 * @export
 */
export const UserSurveyQuestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserSurveyQuestionApiFp(configuration)
    return {
        /**
         * 
         * @param {UserCreateSurveyAnswerDto} userCreateSurveyAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSurveyAnswerControllerCreate(userCreateSurveyAnswerDto: UserCreateSurveyAnswerDto, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.userSurveyAnswerControllerCreate(userCreateSurveyAnswerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSurveyAnswerControllerGet(id: string, options?: any): AxiosPromise<Array<UserSurveyAnswer>> {
            return localVarFp.userSurveyAnswerControllerGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserSurveyQuestionApi - object-oriented interface
 * @export
 * @class UserSurveyQuestionApi
 * @extends {BaseAPI}
 */
export class UserSurveyQuestionApi extends BaseAPI {
    /**
     * 
     * @param {UserCreateSurveyAnswerDto} userCreateSurveyAnswerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSurveyQuestionApi
     */
    public userSurveyAnswerControllerCreate(userCreateSurveyAnswerDto: UserCreateSurveyAnswerDto, options?: AxiosRequestConfig) {
        return UserSurveyQuestionApiFp(this.configuration).userSurveyAnswerControllerCreate(userCreateSurveyAnswerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSurveyQuestionApi
     */
    public userSurveyAnswerControllerGet(id: string, options?: AxiosRequestConfig) {
        return UserSurveyQuestionApiFp(this.configuration).userSurveyAnswerControllerGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkoutScheduleApi - axios parameter creator
 * @export
 */
export const WorkoutScheduleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} trainerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workoutScheduleControllerGetByTrainerId: async (trainerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainerId' is not null or undefined
            assertParamExists('workoutScheduleControllerGetByTrainerId', 'trainerId', trainerId)
            const localVarPath = `/workout-schedule/get/trainer/{trainerId}`
                .replace(`{${"trainerId"}}`, encodeURIComponent(String(trainerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkoutScheduleApi - functional programming interface
 * @export
 */
export const WorkoutScheduleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkoutScheduleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} trainerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workoutScheduleControllerGetByTrainerId(trainerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkoutSchedule>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workoutScheduleControllerGetByTrainerId(trainerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkoutScheduleApi - factory interface
 * @export
 */
export const WorkoutScheduleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkoutScheduleApiFp(configuration)
    return {
        /**
         * 
         * @param {string} trainerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workoutScheduleControllerGetByTrainerId(trainerId: string, options?: any): AxiosPromise<Array<WorkoutSchedule>> {
            return localVarFp.workoutScheduleControllerGetByTrainerId(trainerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkoutScheduleApi - object-oriented interface
 * @export
 * @class WorkoutScheduleApi
 * @extends {BaseAPI}
 */
export class WorkoutScheduleApi extends BaseAPI {
    /**
     * 
     * @param {string} trainerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkoutScheduleApi
     */
    public workoutScheduleControllerGetByTrainerId(trainerId: string, options?: AxiosRequestConfig) {
        return WorkoutScheduleApiFp(this.configuration).workoutScheduleControllerGetByTrainerId(trainerId, options).then((request) => request(this.axios, this.basePath));
    }
}


