import antdJA from 'antd/locale/ja_JP';
import common from './common.json';
import signin from './signin.json';
import forgot from './forgot.password.json';
import menu from './menu.json';
import role from './role.json';
import news from './news.json';
import recruit from './recruit.json';
import help from './help.json';
import faq from './faq.json';
import doc from './doc.json';
import survey from './survey.json';
import customer from './customer.json';
import validate from './validate.json';
import trainer from './trainer.json';
import admin from './admin.json';
import error from './error.json';
import profile from './profile.json';
import inputField from './input-field.json';
import course from './course.json';
import chart from './chart.json';

const jaLang = {
  messages: {
    ...common,
    ...inputField,
    ...signin,
    ...forgot,
    ...menu,
    ...role,
    ...news,
    ...recruit,
    ...help,
    ...faq,
    ...doc,
    ...survey,
    ...customer,
    ...validate,
    ...trainer,
    ...admin,
    ...error,
    ...profile,
    ...course,
    ...chart,
  },
  antd: antdJA,
  locale: 'ja-JP',
};
export default jaLang;
