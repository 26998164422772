const ADMIN = '/admin';
const TRAINER = '';

const ACTION = {
  CREATE: 'create',
  DETAIL: 'detail',

  CREATE_FAQ: 'create-faq',
  DETAIL_FAQ: 'detail-faq',

  CREATE_DOC: 'create-documents',
  DETAIL_DOC: 'detail-documents',
};

export const ADMIN_ROUTE_NAME = {
  DASHBOARD: '',
  SIGNIN: 'signin',
  FORGOT_PASSWORD: 'forgot-password',
  ROLE_MANAGEMENT: 'role-management',
  USER_MANAGEMENT: 'user-management',
  TRAINER_MANAGEMENT: 'trainer-management',
  ADMIN_MANAGEMENT: 'admin-management',
  CHART_MANAGEMENT: 'chart-management',
  NEWS_MANAGEMENT: 'news-management',
  HELP_MANAGEMENT: 'help-management',
  RECRUIMENT_MANAGEMENT: 'recruitment-management',
  SURVEY_MANAGEMENT: 'survey-management',

  PROFILE: 'profile',

  CREATE: 'create',
  DETAIL: 'detail',
  CUSTOMER_COURSE: 'customer-course',

  CREATE_FAQ: 'create-faq',
  DETAIL_FAQ: 'detail-faq',

  CREATE_DOC: 'create-documents',
  DETAIL_DOC: 'detail-documents',
};

export const ADMIN_ROUTE_PATH = {
  DASHBOARD: `${ADMIN}/${ADMIN_ROUTE_NAME.DASHBOARD}`,
  SIGNIN: `${ADMIN}/${ADMIN_ROUTE_NAME.SIGNIN}`,
  FORGOT_PASSWORD: `${ADMIN}/${ADMIN_ROUTE_NAME.FORGOT_PASSWORD}`,

  ROLE_MANAGEMENT: `${ADMIN}/${ADMIN_ROUTE_NAME.ROLE_MANAGEMENT}`,
  CREATE_ROLE: `${ADMIN}/${ADMIN_ROUTE_NAME.ROLE_MANAGEMENT}/${ACTION.CREATE}`,
  DETAIL_ROLE: `${ADMIN}/${ADMIN_ROUTE_NAME.ROLE_MANAGEMENT}/${ACTION.DETAIL}`,

  USER_MANAGEMENT: `${ADMIN}/${ADMIN_ROUTE_NAME.USER_MANAGEMENT}`,
  CREATE_USER: `${ADMIN}/${ADMIN_ROUTE_NAME.USER_MANAGEMENT}/${ACTION.CREATE}`,
  CUSTOMER_COURSE: `${ADMIN}/${ADMIN_ROUTE_NAME.USER_MANAGEMENT}/${ADMIN_ROUTE_NAME.CUSTOMER_COURSE}`,
  TRAINER_MANAGEMENT: `${ADMIN}/${ADMIN_ROUTE_NAME.TRAINER_MANAGEMENT}`,
  CREATE_TRAINER: `${ADMIN}/${ADMIN_ROUTE_NAME.TRAINER_MANAGEMENT}/${ACTION.CREATE}`,
  ADMIN_MANAGEMENT: `${ADMIN}/${ADMIN_ROUTE_NAME.ADMIN_MANAGEMENT}`,
  CREATE_ADMIN: `${ADMIN}/${ADMIN_ROUTE_NAME.ADMIN_MANAGEMENT}/${ACTION.CREATE}`,
  CHART_MANAGEMENT: `${ADMIN}/${ADMIN_ROUTE_NAME.CHART_MANAGEMENT}`,

  NEWS_MANAGEMENT: `${ADMIN}/${ADMIN_ROUTE_NAME.NEWS_MANAGEMENT}`,
  CREATE_NEWS: `${ADMIN}/${ADMIN_ROUTE_NAME.NEWS_MANAGEMENT}/${ACTION.CREATE}`,
  DETAIL_NEWS: `${ADMIN}/${ADMIN_ROUTE_NAME.NEWS_MANAGEMENT}/${ACTION.DETAIL}`,

  HELP_MANAGEMENT: `${ADMIN}/${ADMIN_ROUTE_NAME.HELP_MANAGEMENT}`,
  CREATE_FAQ_MANAGEMENT: `${ADMIN}/${ADMIN_ROUTE_NAME.HELP_MANAGEMENT}/${ACTION.CREATE_FAQ}`,
  DETAIL_FAQ_MANAGEMENT: `${ADMIN}/${ADMIN_ROUTE_NAME.HELP_MANAGEMENT}/${ACTION.DETAIL_FAQ}`,
  CREATE_DOC_MANAGEMENT: `${ADMIN}/${ADMIN_ROUTE_NAME.HELP_MANAGEMENT}/${ACTION.CREATE_DOC}`,
  DETAIL_DOC_MANAGEMENT: `${ADMIN}/${ADMIN_ROUTE_NAME.HELP_MANAGEMENT}/${ACTION.DETAIL_DOC}`,

  RECRUIMENT_MANAGEMENT: `${ADMIN}/${ADMIN_ROUTE_NAME.RECRUIMENT_MANAGEMENT}`,
  CREATE_RECRUIMENT: `${ADMIN}/${ADMIN_ROUTE_NAME.RECRUIMENT_MANAGEMENT}/${ACTION.CREATE}`,
  DETAIL_RECRUIMENT: `${ADMIN}/${ADMIN_ROUTE_NAME.RECRUIMENT_MANAGEMENT}/${ACTION.DETAIL}`,

  SURVEY_MANAGEMENT: `${ADMIN}/${ADMIN_ROUTE_NAME.SURVEY_MANAGEMENT}`,
  CREATE_SURVEY: `${ADMIN}/${ADMIN_ROUTE_NAME.SURVEY_MANAGEMENT}/${ACTION.CREATE}`,

  PROFILE: `${ADMIN}/${ADMIN_ROUTE_NAME.PROFILE}`,
};

export const TRAINER_ROUTE_NAME = {
  DASHBOARD: '',
  SIGNIN: 'signin',
  FORGOT_PASSWORD: 'forgot-password',
  COURSE_MANAGEMENT: 'course-management',
  LESSON: 'lesson',
  CUSTOMER_MANAGEMENT: 'customer-management',
  NEWS_MANAGEMENT: 'news-management',
  CHART_MANAGEMENT: 'chart-management',

  PROFILE: 'profile',

  CREATE: 'create',
  DETAIL: 'detail',
};

export const TRAINER_ROUTE_PATH = {
  DASHBOARD: `${TRAINER}/${TRAINER_ROUTE_NAME.DASHBOARD}`,
  SIGNIN: `${TRAINER}/${TRAINER_ROUTE_NAME.SIGNIN}`,
  FORGOT_PASSWORD: `${TRAINER}/${TRAINER_ROUTE_NAME.FORGOT_PASSWORD}`,

  COURSE_MANAGEMENT: `${TRAINER}/${TRAINER_ROUTE_NAME.COURSE_MANAGEMENT}`,
  CREATE_COURSE_MANAGEMENT: `${TRAINER}/${TRAINER_ROUTE_NAME.COURSE_MANAGEMENT}/${TRAINER_ROUTE_NAME.CREATE}`,

  LESSON: (id: string) => `${TRAINER}/${TRAINER_ROUTE_NAME.COURSE_MANAGEMENT}/${TRAINER_ROUTE_NAME.LESSON}/${id}`,

  CUSTOMER_MANAGEMENT: `${TRAINER}/${TRAINER_ROUTE_NAME.CUSTOMER_MANAGEMENT}`,
  NEWS_MANAGEMENT: `${TRAINER}/${TRAINER_ROUTE_NAME.NEWS_MANAGEMENT}`,

  CHART_MANAGEMENT: `${TRAINER}/${TRAINER_ROUTE_NAME.CHART_MANAGEMENT}`,

  PROFILE: `${TRAINER}/${TRAINER_ROUTE_NAME.PROFILE}`,
};
